import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { alerta, toast, cerrarModal, formatDate, formatearMonedaValue } from '../../../services/utilities';
import { getMovimientos } from '../../../services/Cartera';
import Swal  from 'sweetalert2';

function Resumen(props){

    return(
        <React.Fragment>
            <div className="modal fade" id="modalResumen" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalResumen" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Resumen de Cartera General</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="card">
                                <div className="card-body mt-3">
                                    <span className="badge text-bg-dark m-1">{props.resumen_cartera.fecha}</span>
                                    <ul className="list-group">
                                        <li className="list-group-item d-flex justify-content-between text-secondary">
                                            <label>
                                                <i className="bi bi-arrow-right-circle me-2"></i>
                                                Saldo Anterior
                                            </label>
                                            <label><b>$ {formatearMonedaValue(props.resumen_cartera.totalSaldoAnterior)}</b></label>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between text-secondary">
                                            <label>
                                                <i className="bi bi-arrow-right-circle me-2"></i>
                                                V. Bruta
                                            </label>
                                            <label><b>$ {formatearMonedaValue(props.resumen_cartera.totalVentaBruta)}</b></label>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <label>
                                                <i className="bi bi-arrow-down-circle me-2 text-danger"></i>
                                                N. Vendedor
                                            </label>
                                            <label>$ {formatearMonedaValue(props.resumen_cartera.totalVentaNetaVendedor)}</label>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <label>
                                                <i className="bi bi-arrow-down-circle me-2 text-danger"></i>
                                                N. Promotor
                                            </label>
                                            <label>$ {formatearMonedaValue(props.resumen_cartera.totalVentaNetaPromotor)}</label>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <label>
                                                <i className="bi bi-arrow-down-circle me-2 text-danger"></i>
                                                N. Socio Empresa
                                            </label>
                                            <label>$ {formatearMonedaValue(props.resumen_cartera.totalVentaNetaAdminExterno)}</label>
                                        </li>                                        
                                        <li className="list-group-item d-flex justify-content-between">
                                            <label>
                                                <i className="bi bi-arrow-up-circle me-2 text-success"></i>
                                                Cobros Vendedor
                                            </label>
                                            <label>$ {formatearMonedaValue(props.resumen_cartera.totalCobrosPrestamoVendedor)}</label>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <label>
                                                <i className="bi bi-arrow-up-circle me-2 text-success"></i>
                                                Cobros Promotor
                                            </label>
                                            <label>$ {formatearMonedaValue(props.resumen_cartera.totalCobrosPrestamoPromotor)}</label>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <label>
                                                <i className="bi bi-arrow-up-circle me-2 text-success"></i>
                                                Cobros Socio Empresa
                                            </label>
                                            <label>$ {formatearMonedaValue(props.resumen_cartera.totalCobrosPrestamoAdminExterno)}</label>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <label>
                                                <i className="bi bi-arrow-up-circle me-2 text-success"></i>
                                                Abonos
                                            </label>
                                            <label>$ {formatearMonedaValue(props.resumen_cartera.totalAbonos)}</label>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <label>
                                                <i className="bi bi-arrow-down-circle me-2 text-danger"></i>
                                                Premios
                                            </label>
                                            <label>$ {formatearMonedaValue(props.resumen_cartera.totalPremios)}</label>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <label>
                                                <i className="bi bi-arrow-up-circle me-2 text-success"></i>
                                                Ingresos
                                            </label>
                                            <label>$ {formatearMonedaValue(props.resumen_cartera.totalIngresos)}</label>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <label>
                                                <i className="bi bi-arrow-down-circle me-2 text-danger"></i>
                                                Egresos
                                            </label>
                                            <label>$ {formatearMonedaValue(props.resumen_cartera.totalEgresos)}</label>
                                        </li>
                                        <li className={props.resumen_cartera.totalSaldoActual > 0  ? "list-group-item d-flex justify-content-between text-success" :
                                            "list-group-item d-flex justify-content-between text-danger"}>
                                            <label>
                                                <i className="bi bi-arrow-right-circle me-2"></i>
                                                Total
                                            </label>
                                            <label><b>$ {formatearMonedaValue(props.resumen_cartera.totalSaldoActual)}</b></label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const mapSateToProps = state => {

    return {
        resumen_cartera: state.resumen_cartera,
    }
}
    
export default  connect(mapSateToProps,null)(Resumen);
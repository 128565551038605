import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setIdComercio, setIdComercioSeleccionado, setResumenCartera, setResumenCarteraIndividual } from '../../../actions'
import { alerta, toast } from '../../../services/utilities';
import { getCarteaVendedores } from '../../../services/Cartera';
import Header from "../../header/Header";
import Aside from "../../aside/Aside";
import HeaderModulo from "../../header/HeaderModulo";
import Ajuste from './Ajuste';
import Movimientos from './Movimientos';
import Resumen from './Resumen';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ResumenIndividual from './ResumenIndividual';

function Cartera(props){

    const tooltip1 = (<Tooltip id="tooltip">Ingresos/Egresos</Tooltip>);
    const tooltip2 = (<Tooltip id="tooltip">Movimientos</Tooltip>);
    const tooltip3 = (<Tooltip id="tooltip">Resumen</Tooltip>);

    const [spiner, setSpiner] = useState(false);
    const [verMensaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [carteras, setCarteras] = useState([]);
    const [filter, setFilter] = useState('');
    const [tipoComercio, setTipoComercio] = useState('');

    const [filtros, setFiltros] = useState({
        "fecha":""
    });

    const handleInputFiltrosChange = (event) => {
        const { name, value } = event.target;
        setFiltros({ ...filtros, [name]: value });
        handleConsultarCarteras(value, tipoComercio)
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setTipoComercio(value);

        //Si hay una fecha seleccionada consulta
        if(filtros.fecha.length >= 8){
            handleConsultarCarteras(filtros.fecha, value)
        }
    };
    
    const formatearMoneda = (value) =>{

        const valor = parseFloat(value);
        
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
    
        return value;
    }

    const handleTotalizarCartera = (cartera, fecha) => {

        let resumen = {
            'fecha': fecha,
            'totalSaldoAnterior' : 0,
            'totalSaldoActual' : 0,
            'totalCobrosPrestamoVendedor' : 0,
            'totalCobrosPrestamoPromotor' : 0,
            'totalCobrosPrestamoAdminExterno' : 0,
            'totalVentaNetaEmpresa' : 0,
            'totalVentaNetaPromotor' : 0,
            'totalVentaNetaVendedor' : 0,
            'totalVentaNetaAdminExterno' : 0,
            'totalVentaBruta' : 0,
            'totalPremios' : 0,
            'totalPremiosPromotor' : 0,
            'totalAbonos' : 0,
            'totalEgresos' : 0,
            'totalIngresos' : 0
        }

        cartera.forEach(objeto => {
            resumen.totalSaldoAnterior += objeto.saldo_anterior || 0;
            resumen.totalSaldoActual += objeto.saldo_actual || 0;
            resumen.totalCobrosPrestamoVendedor += objeto.cobros_prestamo_vendedor || 0;
            resumen.totalCobrosPrestamoPromotor += objeto.cobros_prestamo_promotor || 0;
            resumen.totalCobrosPrestamoAdminExterno += objeto.cobros_prestamo_admin_externo || 0;
            resumen.totalVentaNetaEmpresa += objeto.venta_neta_empresa || 0;
            resumen.totalVentaNetaPromotor += objeto.venta_neta_promotor || 0;
            resumen.totalVentaNetaVendedor += objeto.venta_neta_vendedor || 0;
            resumen.totalVentaNetaAdminExterno += objeto.venta_neta_admin_externo || 0;
            resumen.totalVentaBruta += objeto.venta_bruta || 0;
            resumen.totalPremios += objeto.premios || 0;
            resumen.totalPremiosPromotor += objeto.premios_promotor || 0;
            resumen.totalAbonos += objeto.abonos || 0;
            resumen.totalEgresos += objeto.egresos || 0;
            resumen.totalIngresos += objeto.ingresos || 0;
        });
        props.setResumenCartera(resumen);
    }

    const handleConsultarCarteras = async (fecha, tipoComercio) => {

        setVerMensaje(false);
        setVerTable(false);
        setIsDisabled(true);
        setSpiner(true);
        
        try {

            await getCarteaVendedores(fecha, tipoComercio).then( response => {

                if(response.status !== undefined && response.status === 200){
                    if(response.data.cartera.length === 0){
                        setVerMensaje(true);
                    }else{
                        setIsDisabled(false);
                        setCarteras(response.data.cartera);
                        handleTotalizarCartera(response.data.cartera, fecha);
                        setVerTable(true);
                        toast('¡ Perfecto !','Cartera Cargada','success');
                    }
                }else{
                    alerta('Error al consular la cartera en la base de datos');
                }
            });

        }catch (error) {
            alerta('Error no controlado al cargar la infromación de la cartera (cartera/vendedor/handleConsultarCarteras) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    const filteredData = carteras.filter((item) => {

        const nombreNickname = item.nickname !== null && item.nickname  ? item.nickname.toLowerCase().includes(filter.toLowerCase()) : '';
        const nombreMatch = item.nombre_comercio !== null && item.nombre_comercio  ? item.nombre_comercio.toLowerCase().includes(filter.toLowerCase()) : '';
        const PromotorMatch = item.nombre_promotor !== null && item.nombre_promotor ? item.nombre_promotor.toLowerCase().includes(filter.toLowerCase()) : '';
        const idMatch =  item.comercio_id !== null && item.comercio_id ?  item.comercio_id.toString().includes(filter) : '';
        const idPromotorMatch = item.id_promotor !== null && item.id_promotor  ?  item.id_promotor.toString().includes(filter) : '';
        return nombreMatch || PromotorMatch || idMatch || idPromotorMatch || nombreNickname;

    });

    const handleComercioSeleccionado = (id_comercio) => {
        props.setIdComercio(id_comercio)
        props.setIdComercioSeleccionado(id_comercio)
    }

    var total_saldo_anterior = 0;
    var total_v_brutar = 0;
    var total_neto = 0;
    var total_cobro_prestamo = 0;
    var total_abono_prestamo = 0;
    var total_premios = 0;
    var total_ingresos = 0;
    var total_egresos = 0;
    var total_total = 0;

    if(Array.isArray(carteras)){
        
        filteredData.map((cartera) => {

            total_saldo_anterior = total_saldo_anterior + cartera.saldo_anterior;
            total_v_brutar = total_v_brutar + cartera.venta_bruta;

            /*CALCULOS TOTALES DE NETO Y COBROS PARA LOS DIFERENTES TIPOS DE COMERCIOS*/
            if(tipoComercio === 'VENDEDOR'){
                total_neto = total_neto + (cartera.venta_bruta - cartera.venta_neta_vendedor);
                total_cobro_prestamo = total_cobro_prestamo + cartera.cobros_prestamo_vendedor;
            }
            if(tipoComercio === 'PROMOTOR'){
                total_neto = total_neto + (cartera.venta_bruta - (cartera.venta_neta_promotor + cartera.venta_neta_vendedor));
                total_cobro_prestamo = total_cobro_prestamo + cartera.cobros_prestamo_promotor;
            }
            if(tipoComercio === 'ADMIN-EXTERNO'){
                total_neto = total_neto + (cartera.venta_bruta - (cartera.venta_neta_promotor + cartera.venta_neta_vendedor + cartera.venta_neta_admin_externo));
                total_cobro_prestamo = total_cobro_prestamo + cartera.cobros_prestamo_admin_externo;
            }

            total_abono_prestamo = total_abono_prestamo + cartera.abonos;
            total_premios = total_premios + cartera.premios;
            total_ingresos = total_ingresos + cartera.ingresos;
            total_egresos = total_egresos + cartera.egresos;
            total_total = total_total + cartera.saldo_actual;

        });
    }


    const handleResumenCartera = (id) => {
        if(Array.isArray(carteras)){
            const cartera = carteras.find(objeto => objeto.id_cartera == id);          
            if (cartera) {
                props.setResumenCarteraIndividual(cartera);
            }
        }else{
            alerta('Resumen de cartera no encontrado.')
        }
    }
    
    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className='row'>
                        <div className='col-md-2 mb-3'>
                            <div className='input-group'>
                                <select className="form-select form-control" onChange={handleSelectChange} value={tipoComercio}>
                                    <option value="">-Seleccione-</option>
                                    { props.data_sesion.rol_id === 0 ?
                                        <>
                                        <option value="PROMOTOR">Promotor</option>
                                        <option value="ADMIN-EXTERNO">Socio Emp</option>
                                        </>
                                        :''
                                    }
                                    { props.data_sesion.rol_id === 1 ?
                                        <>
                                        <option value="PROMOTOR">Promotor</option>
                                        </>
                                        :''
                                    }
                                    { props.data_sesion.rol_id === 2 ?
                                        <>
                                        <option value="VENDEDOR">Vendedor</option>
                                        </>
                                        :''
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha" 
                                    name="fecha" 
                                    value={filtros.fecha}
                                    onChange={handleInputFiltrosChange} 
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                <input 
                                    type="text" 
                                    className="form-control form-control-sm" 
                                    placeholder="Buscar..." 
                                    aria-label="Buscar" 
                                    aria-describedby="basic-addon1" 
                                    value={filter}
                                    disabled={isDisabled}
                                    onChange={(e) => setFilter(e.target.value)} 
                                />
                            </div>
                        </div>
                        <div className='col-md-1 mb-3' style={{ display: 'flex', alignItems: 'center' }}>
                            <button type="button" className="btn btn-outline-primary btn-md" disabled={isDisabled} onClick={() => handleConsultarCarteras(filtros.fecha, tipoComercio)} >
                                <i className="bi bi-arrow-repeat"></i>
                            </button>
                        </div>
                        <div className='col-md-3 mb-3 d-flex justify-content-end'>
                            <button 
                                type="button" 
                                className="btn btn-primary btn-md" 
                                disabled={isDisabled} 
                                data-bs-toggle="modal" 
                                data-bs-target="#modalResumen"
                            >
                                Resumen General
                            </button>
                        </div>
                    </div>
                    {verTable ?
                    <>  
                        <div className='d-flex justify-content-end'>
                            <span className='text-secondary mx-1' style={{fontSize :'12px'}}>Total Registros {filteredData.length}</span>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className='table-container'>
                                    <div className="table-responsive-sm">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr className='text-center' style={{fontSize : '12px'}}>
                                                    <td>ID</td>
                                                    { tipoComercio === 'VENDEDOR'
                                                        ?   <>
                                                            <td><b>Vendedor</b></td>
                                                            <td><b>Promotor</b></td>
                                                            </>
                                                        :''
                                                    }
                                                    { tipoComercio === 'PROMOTOR'
                                                        ?   <>
                                                            <td><b>Promotor</b></td>
                                                            <td><b>Socio Emp</b></td>
                                                            </>
                                                        :''
                                                    }
                                                    { tipoComercio === 'ADMIN-EXTERNO'
                                                        ? <td><b>Socio Emp</b></td>
                                                        :''
                                                    } 
                                                    <td><b>Saldo Anterior</b></td>
                                                    <td><b>V. Bruta</b></td>
                                                    <td><b>Neto</b></td>
                                                    
                                                    {/* props.data_sesion.rol_id !== 2 ?
                                                        <td><b>Neto Empresa</b></td>
                                                    :''*/}

                                                    <td><b>Cobro Prestamo</b></td>
                                                    {/*<td><b>Abono Prestamo</b></td>*/}
                                                    <td><b>Premios</b></td>
                                                    <td><b>Ingresos</b></td>
                                                    <td><b>Egresos</b></td>
                                                    <td><b>Total</b></td>
                                                    <td><i className="bi bi-calculator"></i></td>
                                                    <td><i className="bi bi-calendar2-plus"></i></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {Array.isArray(carteras) ? filteredData.map((cartera, index) => (
                                                <tr className='text-center'  style={{fontSize : '12px'}} key={index}>
                                                    <td>{cartera.id_cartera}</td>
                                                    <td><p>{cartera.comercio_id} - {cartera.nickname} - {cartera.nombre_comercio}</p></td>

                                                    { tipoComercio === 'VENDEDOR'
                                                        ? <td><p>{cartera.id_promotor !== null ? cartera.id_promotor :''} - {cartera.nombre_promotor}</p></td>                                               
                                                        :''
                                                    }
                                                    { tipoComercio === 'PROMOTOR'
                                                        ? <td><p>{cartera.id_admin_externo !== null ? cartera.id_admin_externo :''} - {cartera.nombre_admin_externo}</p></td>
                                                        :''
                                                    }

                                                    <td className={cartera.saldo_anterior <= 0 ? 'text-danger' : 'text-success'}>{formatearMoneda(cartera.saldo_anterior)}</td>

                                                    <td>{formatearMoneda(cartera.venta_bruta)}</td>
                                                    
                                                    { /*CALCULO TOTAL DE NETO PARA LOS DIFERENTES TIPOS DE COMERCIOS*/}
                                                    { tipoComercio === 'VENDEDOR' ?
                                                        <td className='text-success'>{formatearMoneda(cartera.venta_bruta - cartera.venta_neta_vendedor)}</td>
                                                    :''}
                                                    { tipoComercio === 'PROMOTOR' ?
                                                        <td className='text-success'>{formatearMoneda(cartera.venta_bruta - (cartera.venta_neta_promotor + cartera.venta_neta_vendedor))}</td>
                                                    :''}
                                                    { tipoComercio === 'ADMIN-EXTERNO' ?
                                                        <td className='text-success'>{formatearMoneda(cartera.venta_bruta - (cartera.venta_neta_promotor + cartera.venta_neta_vendedor + cartera.venta_neta_admin_externo))}</td>
                                                    :''}                                            
                                                    
                                                    {/* props.data_sesion.rol_id !== 2 ?
                                                        <td className='text-success'>{formatearMoneda(cartera.venta_neta_empresa)}</td>
                                                    :''*/}

                                                    <td className='text-success'>
                                                        { tipoComercio === 'VENDEDOR'
                                                            ?formatearMoneda(cartera.cobros_prestamo_vendedor)
                                                            :tipoComercio === 'PROMOTOR' 
                                                            ?formatearMoneda(cartera.cobros_prestamo_promotor)
                                                            :formatearMoneda(cartera.cobros_prestamo_admin_externo)
                                                        }   
                                                    </td>
                                                    {/*<td className='text-danger'>{formatearMoneda(cartera.abonos)}</td>*/}
                                                    <td className='text-danger'>{formatearMoneda(cartera.premios)}</td>
                                                    <td className='text-success'>{formatearMoneda(cartera.ingresos)}</td>
                                                    <td className='text-danger'>{formatearMoneda(cartera.egresos)}</td>
                                                    <td className={cartera.saldo_actual <= 0 ? 'text-danger' : 'text-success'}>{formatearMoneda(cartera.saldo_actual)}</td>
                                                    <td>
                                                        <OverlayTrigger placement="top" overlay={tooltip1}>
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-outline-success btn-sm "
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#modalAjuste"
                                                                data-id={cartera.comercio_id}
                                                                onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}                                                        
                                                                >
                                                                <i 
                                                                    className="bi bi-calculator"
                                                                    data-id={cartera.comercio_id}
                                                                    onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                                                >
                                                                </i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger placement="top" overlay={tooltip2}>
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-outline-dark btn-sm"
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#modalMovimientos"
                                                                data-id={cartera.comercio_id}
                                                                onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}                                                        
                                                                >
                                                                <i 
                                                                    className="bi bi-calendar2-plus"
                                                                    data-id={cartera.comercio_id}
                                                                    onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                                                >
                                                                </i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger placement="top" overlay={tooltip3}>
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-outline-primary btn-sm"
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#modalResumenIndividual"
                                                                data-id={cartera.id_cartera}
                                                                onClick={(e) => handleResumenCartera(e.target.getAttribute('data-id'))}                                                        
                                                                >
                                                                <i 
                                                                    className="bi bi-graph-up-arrow"
                                                                    data-id={cartera.id_cartera}
                                                                    onClick={(e) => handleResumenCartera(e.target.getAttribute('data-id'))}
                                                                >
                                                                </i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            )) : <tr></tr>}
                                            <tr className='text-center table-active'  style={{fontSize : '12px'}}>
                                                { tipoComercio !== 'ADMIN-EXTERNO'?
                                                    <td></td>
                                                :''}
                                                <td></td>
                                                <td><b>Total:</b></td>
                                                <td className={total_saldo_anterior <= 0 ? 'text-danger' : 'text-success'}><b>${formatearMoneda(total_saldo_anterior)}</b></td>
                                                <td><b>${formatearMoneda(total_v_brutar)}</b></td>
                                                <td className='text-success'><b>${formatearMoneda(total_neto)}</b></td>
                                                <td className='text-success'><b>${formatearMoneda(total_cobro_prestamo)}</b></td>
                                                {/*<td className='text-danger'><b>${formatearMoneda(total_abono_prestamo)}</b></td>*/}
                                                <td className='text-danger'><b>${formatearMoneda(total_premios)}</b></td>
                                                <td className='text-success'><b>${formatearMoneda(total_ingresos)}</b></td>
                                                <td className='text-danger'><b>${formatearMoneda(total_egresos)}</b></td>
                                                <td className={total_total <= 0 ? 'text-danger' : 'text-success'}><b>${formatearMoneda(total_total)}</b></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :''}
                    {spiner ?
                    <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                    :''}
                    {verMensaje ?
                    <div className='text-center text-secondary my-4'>
                        <h3>No hay registros seleccionados</h3>
                        <p>Por favor, elija una fecha para buscar información sobre el registro de la cartera</p>
                    </div>
                    :''}
                </section>
            </main>
            <Ajuste></Ajuste>
            <Movimientos></Movimientos>
            <Resumen></Resumen>
            <ResumenIndividual></ResumenIndividual>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setIdComercio,
    setIdComercioSeleccionado,
    setResumenCartera,
    setResumenCarteraIndividual
};

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
    }
}


export default connect(mapSateToProps, mapDispatchToProps)(Cartera)


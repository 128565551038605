import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Swal  from 'sweetalert2';
import { setListaEncabezadoResultado } from '../../../../actions'
import { getEncabezados, guardarResultado } from '../../../../services/Resultados';
import { currentDate } from '../../../../services/utilities';
import { getDia } from '../../../../services/Loterias';
import { alerta, toast, cerrarModal } from '../../../../services/utilities';


function Nuevo(props){

    useEffect(() => {

        var fechaActual = new Date();
        fechaActual.setDate(fechaActual.getDate() - 1);

        var fechaMaxima = fechaActual.toISOString().split('T')[0];
        var inputFecha = document.getElementById("fecha");
        inputFecha.max = fechaMaxima;

        handleLoteriasDia(currentDate());

    }, []);

    const [bloqueFechaRegistrada, setBloqueFechaRegistrada] = useState(false);
    const [encabezado_resultado, setEncabezadoResultado] = useState({
        "observacion": "",
        "fecha":currentDate(),
        "estado_id": 5
    });
    const [items_resultado, setItemsResultado] = useState([]);
 
    const handleInputEncabezadoResultadoChange = (event) => {
        const { name, value } = event.target;
        setEncabezadoResultado({ ...encabezado_resultado, [name]: value });

        if(name == 'fecha'){
            handleLoteriasDia(value);
        }
    };

    const handleInputItemLoteriaChange = (index, event) => {

        const { name, value } = event.target;
        const updatedItemsResultado = [...items_resultado];

        let temp = {
            "prefijo": items_resultado[index].prefijo,
            "id_loteria": items_resultado[index].id_loteria,
            "numero_externo": name == `numero_externo_${index}` ? value : items_resultado[index].numero_externo,
            "serie_externo": name == `serie_externo_${index}` ? value : items_resultado[index].serie_externo,
            "astro_externo": name == `astro_externo_${index}` ? value : items_resultado[index].astro_externo
        }
        updatedItemsResultado[index] = temp;
        setItemsResultado(updatedItemsResultado);
    };

    const handleLoteriasDia = (fecha) =>{

        const currentDate = new Date();
        const selectedDate = new Date(fecha)
        
        if(selectedDate > currentDate){
            alerta('La fecha seleccionada no puede ser mayor a la fecha actual');
            return;
        }

        getDia(fecha).then(response => {
            if(response.status === 200){

                if((response.data.data[0].numero) !== null){
                    setBloqueFechaRegistrada(true);
                }else{
                    setBloqueFechaRegistrada(false);
                }
                
                setItemsResultado(response.data.data)
            }else{
                setItemsResultado(null)
                alerta('No se cargo la tabla de loterias o no hay loterias para el dia seleccionado',response.response.data.status_message??response.message,'warning');
            }
        });
    }


    const handleValidarCampos = () => {

        let msg = false;
        const currentDate = new Date();
        const selectedDate = new Date(encabezado_resultado.fecha)

        if(selectedDate > currentDate){
            msg = 'La fecha seleccionada no puede ser mayor a HOY';
        }else if(items_resultado.length === 0){
            msg = 'No es posible registrar resultados si no se han asociado loterías para la fecha seleccionada.';
        }

        items_resultado.forEach((resultado, index) => {

            if (resultado.numero_externo === null || resultado.numero_externo === '') 
                msg =`El campo "Numero" de la loteria "${resultado.prefijo}" no puede estar vacio`;
            
            if(resultado.numero_externo !== null && resultado.numero_externo.length > 4)
                msg =`El valor del campo "Numero" de la loteria "${resultado.prefijo}" no puede ser mayor a 4 digitos`;

            if(resultado.serie_externo !== null &&  resultado.serie_externo.length > 3)
                msg =`El valor del campo "Serie" de la loteria "${resultado.prefijo}" no puede ser mayor a 3 digitos`;

        });
        
        msg = false;
        return msg;
    }

    const handleRefrescarTablaResultados = () =>{

        getEncabezados().then(response => {
            if(response.status === 200){
                props.setListaEncabezadoResultado(response.data.data)
            }else{
                alerta('No se cargo la tabla de resultados o no hay resultados actualemente creados',response.response.data.status_message??response.message,'warning');
            }
        });
    }

    const handleGuardarResultado = () => {

        if(handleValidarCampos()){
            alerta(handleValidarCampos());
            return;
        }
        
        const data = {
            "encabezado_resultado": encabezado_resultado,
            "resultado_loteria": items_resultado
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la información del resultado ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                guardarResultado(data).then(response => {
                    if(response.status == 201){
                        handleRefrescarTablaResultados()
                        cerrarModal('modalNuevaResultado');
                        handleLoteriasDia(currentDate());
                        toast('¡ Perfecto !','Resultado guardado exitosamente','success');
                    }else{
                        alerta('Se presento un error al intentar guardar el resultado',response.response.data.status_message??response.message);
                    }
                });
            }
        });        
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalNuevaResultado" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalNuevaResultado" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Crear Nuevo Resultado</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            id="fecha" 
                                            name="fecha" 
                                            value={encabezado_resultado.fecha} 
                                            onChange={handleInputEncabezadoResultadoChange} 
                                        />
                                        <label htmlFor="floatingName">Fecha:</label>
                                    </div>
                                </div>
                                <div className="col-md-6 d-none">
                                    <div className="form-floating mb-3">
                                        <select
                                            className="form-select" 
                                            id="estado_id" 
                                            name="estado_id" 
                                            aria-label="State"
                                            disabled={bloqueFechaRegistrada}
                                            value={encabezado_resultado.estado_id}
                                            onChange={handleInputEncabezadoResultadoChange} 
                                        >
                                            <option value="5">Pendiente</option>
                                            <option value="2">Aprobado</option>
                                        </select>
                                        <label htmlFor="estado_id">Estado:</label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="observacion" 
                                            name="observacion"
                                            disabled={bloqueFechaRegistrada}
                                            value={encabezado_resultado.observacion} 
                                            onChange={handleInputEncabezadoResultadoChange} 
                                            />
                                        <label htmlFor="floatingName">Observación:</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <table className="table table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Loteria</th>
                                            <th className="text-left" style={{width:'20%'}}>Numero</th>
                                            <th className="text-left" style={{width:'20%'}}>Serie</th>
                                            <th  className="text-left">Astro</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {Array.isArray(items_resultado) ?items_resultado.map((dia, index) => (
                                        <tr  key={index}>
                                            <td>{dia.prefijo}</td>
                                            <td>
                                                <div className="input-group input-group-sm">
                                                    <input 
                                                        type="number" 
                                                        className="form-control" 
                                                        name={`numero_externo_${index}`}
                                                        disabled={bloqueFechaRegistrada}
                                                        value={items_resultado[index].numero_externo??''}
                                                        onChange={(e) => handleInputItemLoteriaChange(index, e)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="input-group input-group-sm">
                                                    <input 
                                                        type="number" 
                                                        className="form-control" 
                                                        name={`serie_externo_${index}`}
                                                        disabled={bloqueFechaRegistrada}
                                                        value={items_resultado[index].serie_externo??''}
                                                        onChange={(e) => handleInputItemLoteriaChange(index, e)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="input-group input-group-sm">
                                                    <select
                                                        className="form-select" 
                                                        id="signo" 
                                                        name={`astro_externo_${index}`}
                                                        aria-label="State"
                                                        disabled={bloqueFechaRegistrada}
                                                        value={items_resultado[index].astro_externo??''}
                                                        onChange={(e) => handleInputItemLoteriaChange(index, e)}
                                                    >
                                                        <option value="">n/a</option>
                                                        <option value="Aries">Aries</option>
                                                        <option value="Tauro">Tauro</option>
                                                        <option value="Geminis">Geminis</option>
                                                        <option value="Cancer">Cáncer</option>
                                                        <option value="Leo">Leo</option>
                                                        <option value="Virgo">Virgo</option>
                                                        <option value="Libra">Libra</option>
                                                        <option value="Escorpion">Escorpion</option>
                                                        <option value="Sagitario">Sagitario</option>
                                                        <option value="Capricornio">Capricornio</option>
                                                        <option value="Acuario">Acuario</option>
                                                        <option value="Piscis">Piscis</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <tr></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" disabled={bloqueFechaRegistrada} onClick={handleGuardarResultado}>Guardar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => handleLoteriasDia(encabezado_resultado.fecha)}>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapDispatchToProps  = {
    setListaEncabezadoResultado
};
export default  connect(null, mapDispatchToProps)(Nuevo);
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { setIdPlanPago, setTablaPlanesPago } from '../../../../actions'
import { getPlanPago, editarPlanPago, getPlanesPago } from '../../../../services/PlanesDePago';
import { alerta, toast, cerrarModal } from '../../../../services/utilities';

function Editar(props){

    useEffect(() => {

        if(props.id_zona != 0){
            getPlanPago(props.id_plan_pago).then(response => {
                if(response.status === 200){
                    setPlan(response.data.data[0]);
                    handleListaModalidadesPlanPago(response.data.data[0])
                }else{
                    alerta('Error al cargar el plan de pago, intente nuevamente');
                }
            })
        }

    }, [props.id_plan_pago]);

    const [captionTablaModalidades, setCaptionTablaModalidades] = useState('');
    const [lista_modalidades_juego, setListaModalidadesJuego] = useState();
    const [btn_guardar, setBtnGuardar] = useState(true);

    const [plan, setPlan] = useState({
        "nombre": "",
        "estado_id":"",
        "modalidades": []
    });

    const [item_plan_pago, setItemPlanPago] = useState({
        "id_modalidad_juego":"",
        "valor": ""
    });

    const handleInputPlan = (event) => {
        const { name, value } = event.target;
        setPlan({ ...plan, [name]: value });
        setBtnGuardar((name === "nombre" && value.length == 0) ? true : false);
    };
    
    const handleInputItemPlan = (event) => {
        const { name, value } = event.target;
        setItemPlanPago({ ...item_plan_pago, [name]: value });
    };

    const handleListaModalidadesPlanPago = (plan) => {

        if(Array.isArray(plan.modalidades)){
            setListaModalidadesJuego(plan.modalidades.map((r, indice) => (
                <tr key={indice} id={'tr-item-'+indice}>
                    <td>{handleNombreModalidad(r.id_modalidad_juego)}</td>
                    <td>{r.valor}</td>
                    <td>
                        <button 
                            type="button" 
                            className="btn btn-danger btn-sm" 
                            title="Eliminar item" 
                            onClick={() => handleDelteItem(indice,plan)}>
                            <i className="bi bi-trash"></i>
                        </button>
                    </td>
                </tr>
           )));

           setCaptionTablaModalidades(plan.modalidades.length == 0 ? '' : 'd-none');
           setBtnGuardar(plan.modalidades.length == 0 ? true : false);
        }
    }

    const handleNombreModalidad = (id_modalidad_juego) =>{
        const item_plan = props.listaModalidades.find(item => item.id_modalidad_juego == id_modalidad_juego);
        return item_plan !== undefined ? item_plan.nombre : '' ;
    }

    const handleAgregarItem= () => {

        if(plan.nombre == ''){
            alerta('El nombre del plan no debe estar vacío.','Por favor digite el nombre del plan');
            return;
        }else if(item_plan_pago.id_modalidad_juego == ''){
            alerta('Seleccione una modalidad de juego','Por favor seleccione una modalidad valida');
            return;
        }else if(item_plan_pago.valor == ''){
            alerta('Debe digitar un valor igual o mayor a cero','Por favor digite un valor valido');
            return;
        }else if(plan.modalidades.find(item => item.id_modalidad_juego == item_plan_pago.id_modalidad_juego) !== undefined){
            alerta('La modalidad de juego ya esta agregada en la lista','Por favor seleccione otra modalidad');
            return;
        }

        const nuevo_plan = {
            ...plan,
            modalidades: [...plan.modalidades, item_plan_pago],
        };

        setPlan(nuevo_plan);
        
        setItemPlanPago({
            "id_modalidad_juego":"",
            "valor": 0
        });

        handleListaModalidadesPlanPago(nuevo_plan);
    }

    const handleDelteItem = (indice,plan) => {
        plan.modalidades.splice(indice, 1);
        handleListaModalidadesPlanPago(plan)
    }

    const handleRefrescarTablaPlanesPago = () => {

        getPlanesPago().then(response => {
            if(response.status === 200){
                props.setTablaPlanesPago(response.data.data)
            }else{
                alerta('No se cargo la tabla de planes de pago o no hay actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });
    }

    const handleGuardarPlan = () => {

        if(plan.modalidades.length == 0){
            alerta('No es posible guardar un plan sin modalidades de juego');
            return;
        }
        
        Swal.fire({
            title: "¿ Esta seguro de guardar la información del Plan de Pagos ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                editarPlanPago(plan).then(response => {
                    if(response.status == 200){
                        props.setIdPlanPago(0);
                        handleRefrescarTablaPlanesPago();
                        cerrarModal('modalEditarPlan');
                        toast('¡ Perfecto !','Plan de pago editado exitosamente','success');
                    }else{
                        alerta('Se presento un error al intentar guardar el plan de pagos',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalEditarPlan" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalEditarPlan" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Editar Plan de Pagos</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="nombre" name="nombre" onChange={handleInputPlan}  value={plan.nombre} />
                                        <label htmlFor="floatingName">Nombre Plan: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating mb-3">
                                        <select 
                                            className="form-select" 
                                            id="estado_id" 
                                            name="estado_id" 
                                            aria-label="State"
                                            onChange={handleInputPlan}
                                            value={plan.estado_id}
                                            >
                                            <option value="1">Activo</option>
                                            <option value="3">Inactivo</option>
                                        </select>
                                        <label htmlFor="estado_id">Estado: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                            </div>
                            <h5 className="card-title text-left">Items:</h5>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="form-floating mb-3">
                                        <select className="form-select" id="id_modalidad_juego"  name="id_modalidad_juego" aria-label="State" value={item_plan_pago.id_modalidad_juego} onChange={handleInputItemPlan}>
                                            <option value="">-- Seleccion --</option>
                                            {props.listaModalidades.map(opcion => (
                                                <option key={opcion.id_modalidad_juego} value={opcion.id_modalidad_juego}>{opcion.nombre}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="id_modalidad_juego">Modalidad de Juego: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control input-sm"  id="valor" name="valor" value={item_plan_pago.valor} onChange={handleInputItemPlan} />
                                        <label htmlFor="floatingName">Valor: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-3 pt-3">
                                    <button type="button" className="btn btn-success" onClick={handleAgregarItem}>Agregar</button>
                                </div>
                            </div>
                            <div className="row p-3">
                                <table className="table table-sm table-hover table-borderless"style={{ width : "90%"}}>
                                    <caption className={captionTablaModalidades}>
                                        <h5 className='text-secondary text-center'>No hay modalidades agregadas</h5>
                                    </caption>
                                    <tbody>
                                        {lista_modalidades_juego}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-primary"
                                disabled={btn_guardar} 
                                onClick={handleGuardarPlan}
                            >Guardar
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-secondary" 
                                data-bs-dismiss="modal" 
                                onClick={() => props.setIdPlanPago(0)}
                            >Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        listaModalidades: state.listaModalidades,
        id_plan_pago: state.id_plan_pago
    }
}

const mapDispatchToProps  = {
    setIdPlanPago,
    setTablaPlanesPago
};

export default  connect(mapSateToProps, mapDispatchToProps)(Editar);
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Header from "../../header/Header";
import HeaderModulo from "../../header/HeaderModulo";
import Aside from "../../aside/Aside";
import { alerta, toast, formatDate } from '../../../services/utilities';
import { getReportePrestamosComercio, getReporteExtractoPrestamosComercio } from '../../../services/Reportes';
import DetalleVenta from './DetalleVenta';

function PrestamosSolicitados(props){

    useEffect(() => {

    }, []);

    const [spinerTabla, setSpinerTabla] = useState(false);
    const [verMensaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(false);
    const [spinerTablaExtracto, setSpinerTablaExtracto] = useState(false);
    const [verMensajeExtracto, setVerMensajeExtracto] = useState(true);
    const [verTableExtracto, setVerTableExtracto] = useState(false);
    const [listaPrestamos, setlistaPrestamos] = useState([]);
    const [listaExtractoPrestamos, setlistaExtractoPrestamos] = useState([]);
    const [saldoActualPrestamo, setSaldoActualPrestamo] = useState(0);
    const [totalPrestado, setTotalPrestado] = useState(0);

    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date())
    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }

    const handleBuscarPrestamos = async () => {

        setVerMensaje(false);
        setSpinerTabla(true);
        setVerTable(false);

        try {
            await getReportePrestamosComercio(filtro.fecha_ini, filtro.fecha_fin).then( response => {

                if(response.status !== undefined && response.status === 200){                   
                    setSaldoActualPrestamo(response.data.saldo_actual_prestamo);
                    setTotalPrestado(response.data.prestamos.valor);                    
                    if(response.data.prestamos.detalle.length > 0){
                        setlistaPrestamos(response.data.prestamos.detalle);
                        setVerTable(true);
                    }else{
                        setVerMensaje(true)
                        setVerTable(false);
                    }
                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de prestamos  (reportes/prestamos/handleBuscarPrestamos) ->'+ error);
        }finally {
            setSpinerTabla(false);
            
        }
    }

    const handleReporteExtractoPrestamosComercio = async () => {

        setVerMensajeExtracto(false);
        setSpinerTablaExtracto(true);
        setVerTableExtracto(false);

        try {
            await getReporteExtractoPrestamosComercio(filtro.fecha_ini, filtro.fecha_fin).then( response => {
                if(response.status !== undefined && response.status === 200){
                    if(response.data.extracto.length > 0){
                        setlistaExtractoPrestamos(response.data.extracto);
                        setVerTableExtracto(true);
                    }else{
                        setVerMensajeExtracto(true)
                        setVerTableExtracto(false);
                    }
                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de prestamos  (reportes/prestamos/handleBuscarPrestamos) ->'+ error);
        }finally {
            setSpinerTablaExtracto(false);
        }
    }

    const hanldeBtnBuscar = () => {
        handleBuscarPrestamos();
        handleReporteExtractoPrestamosComercio();
    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className='row'>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Desde:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_ini" 
                                    name="fecha_ini" 
                                    value={filtro.fecha_ini}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Hasta:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_fin" 
                                    name="fecha_fin" 
                                    value={filtro.fecha_fin}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                
                        <div className="col-md-1">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary btn-md"
                                    onClick={hanldeBtnBuscar}
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>         
                    <div className='row'>
                        <div className='col-md-5'>
                            <div className="card">
                                <div className='card-header'>
                                    <h4>Resumen de Prestamos:</h4>
                                    <div className="div-card-totales">                          
                                        <div className="card-totales">
                                            <h6 className="m-0"><b>Total Prestado</b></h6>
                                            <h6 className="text-secondary m-0"><b>$ {formatearMoneda(totalPrestado)}</b></h6>
                                        </div>                              
                                        <div className="card-totales">
                                            <h6 className="m-0"><b>Deuda Actual</b></h6>
                                            <h6 className="text-danger m-0"><b>$ {formatearMoneda(saldoActualPrestamo)}</b></h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {verTable ?
                                        <div className='table-container' style={{ overflow : 'auto', height:"200px"}}>
                                            <div className="table-responsive-sm">
                                                <table className='table table-hover'>
                                                    <thead>
                                                    <tr className='text-center'>
                                                        <td><b>Fecha</b></td>
                                                        <td><b>Hora</b></td>
                                                        <td><b>Valor</b></td>
                                                        <td><b>Observación</b></td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>                                            
                                                    {Array.isArray(listaPrestamos) ? listaPrestamos.map((prestamo, index) => (
                                                        <tr className='text-center' key={index}>
                                                            <td><span className="badge text-bg-dark">{prestamo.fecha}</span></td>
                                                            <td><span className="badge text-bg-dark">{prestamo.hora}</span></td>
                                                            <td><span className="badge text-bg-success">$ {formatearMoneda(prestamo.valor)}</span></td>
                                                            <td style={{fontSize : '12px'}}>{prestamo.descripcion}</td>
                                                        </tr>
                                                    )) : <tr></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> 
                                    :''}   

                                    {verMensaje ?               
                                    <div className='text-center text-secondary my-4'>
                                        <h4>Sin resultado de búsqueda para los filtros aplicados.</h4>
                                        <p>Por favor elija una combinación de filtros diferente.</p>
                                    </div> 
                                    :''}               
                                    
                                    {spinerTabla ?
                                        <div className="d-flex justify-content-center align-items-center" style={{height:300}}>
                                            <div className="spinner-border" role="status"></div>                 
                                        </div>
                                    :''}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className="card">
                                <div className='card-header'>
                                    <h4>Extracto:</h4>
                                </div>
                                <div className="card-body">
                                    { verTableExtracto ?
                                        <div className='table-container' style={{ overflow : 'auto', height:"275px"}}>
                                            <div className="table-responsive-sm">
                                                <table className='table table-hover'>
                                                    <thead>
                                                    <tr className='text-center'>
                                                        <td><b>Transaccion</b></td>
                                                        <td><b>Fecha</b></td>
                                                        <td><b>Tipo Transacion</b></td>
                                                        <td><b>Valor</b></td>
                                                        <td><b>detalle</b></td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>                                            
                                                    {Array.isArray(listaExtractoPrestamos) ? listaExtractoPrestamos.map((prestamo, index) => (
                                                        <tr className='text-center' key={index}>
                                                            <td><span className="badge text-bg-warning"># {prestamo.id_transaccion}</span></td>
                                                            <td><span className="badge text-bg-dark">{prestamo.fecha} {prestamo.hora}</span></td>
                                                            <td><b>{prestamo.tipo_transaccion}</b></td>

                                                            {prestamo.transaccion_tipo_id === 1 && props.data_sesion.rol_id === 1 ? 
                                                                <td><span className="badge text-bg-danger">$ {formatearMoneda(prestamo.cobro_admin_externo)}</span></td>
                                                            : ''}

                                                            {prestamo.transaccion_tipo_id === 1 && props.data_sesion.rol_id === 2 ? 
                                                                <td><span className="badge text-bg-danger">$ {formatearMoneda(prestamo.cobro_promotor)}</span></td>
                                                            : ''}

                                                            {prestamo.transaccion_tipo_id === 1 && props.data_sesion.rol_id === 3 ? 
                                                                <td><span className="badge text-bg-danger">$ {formatearMoneda(prestamo.cobro_vendedor)}</span></td>
                                                            : ''}

                                                            {prestamo.transaccion_tipo_id === 2 ? 
                                                                <td><span className="badge text-bg-success">$ {formatearMoneda(prestamo.valor)}</span></td>
                                                            : ''}

                                                            {prestamo.transaccion_tipo_id === 3 ? 
                                                                <td><span className="badge text-bg-info">$ {formatearMoneda(prestamo.valor)}</span></td>
                                                            : ''}

                                                            <td style={{fontSize : '12px'}}>{prestamo.descripcion}</td>
                                                        </tr>
                                                    )) : <tr></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> 
                                    :''}

                                    {verMensajeExtracto ?               
                                    <div className='text-center text-secondary my-4'>
                                        <h4>Sin resultado de búsqueda para los filtros aplicados.</h4>
                                        <p>Por favor elija una combinación de filtros diferente.</p>
                                    </div> 
                                    :''}               
                                    
                                    {spinerTablaExtracto ?
                                        <div className="d-flex justify-content-center align-items-center" style={{height:100}}>
                                            <div className="spinner-border" role="status"></div>                 
                                        </div>
                                    :''}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <DetalleVenta></DetalleVenta>
        </React.Fragment>
    )
}

const mapSateToProps = state => {
    return {
        data_sesion: state.data_sesion,
    }
}

export default connect(mapSateToProps, null)(PrestamosSolicitados)
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setIdComercio, setIdComercioSeleccionado } from '../../../actions'
import { alerta, toast, formatDate, formatearMonedaValue } from '../../../services/utilities';
import { getReporteCartera } from '../../../services/Cartera';
import Header from "../../header/Header";
import Aside from "../../aside/Aside";
import HeaderModulo from "../../header/HeaderModulo";
import Movimientos from './Movimientos';


function MiCartera(props){

    useEffect(() => {
        handleConsultarCartera(formatDate(new Date()),formatDate(new Date()));
    }, []);

    const [spiner, setSpiner] = useState(false);
    const [verMnesaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [cartera, setCartera] = useState([]);

    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date())
    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const handleComercioSeleccionado = (id_comercio) => {
        props.setIdComercio(id_comercio)
        props.setIdComercioSeleccionado(id_comercio)
    }

    const handleConsultarCartera = async (fecha_ini, fecha_fin) => {

        setVerMensaje(false);
        setVerTable(false);
        setIsDisabled(true);
        setSpiner(true);
        
        try {
            await getReporteCartera(fecha_ini, fecha_fin).then( response => {
                if(response.status !== undefined && response.status === 200){
                    if(response.data.cartera.length === 0){
                        setVerMensaje(true);
                    }else{
                        setIsDisabled(false);
                        setCartera(response.data.cartera);
                        setVerTable(true);
                        toast('¡ Perfecto !','Cartera Cargada','success');
                    }
                }else{
                    alerta('Error al consular la cartera en la base de datos');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de la cartera (cartera/Micartera/handleConsultarCartera)');
        }finally {
            setSpiner(false);
        }
    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={'Mi '+props.modulo}></HeaderModulo>
                <section className="section">
                    <div className='row mb'>
                        <div className='col-md-3 mb-3'>
                            <label className="mb-1">Desde:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_ini" 
                                    name="fecha_ini" 
                                    value={filtro.fecha_ini}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-3 mb-3'>
                            <label className="mb-1">Hasta:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_fin" 
                                    name="fecha_fin" 
                                    value={filtro.fecha_fin}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className="col-md-1">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <button type="button" className="btn btn-outline-primary btn-md" onClick={() => handleConsultarCartera(filtro.fecha_ini, filtro.fecha_fin)} >
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr className='m-0'/>
                    {verTable ?
                    <div className="card">
                        <div className="card-body">
                            <div className='table-container'>
                                <div className="table-responsive-sm">
                                    <table className='table table-hover'>
                                        <thead>
                                            <tr className='text-center' style={{fontSize : '12px'}}>
                                                <td>ID</td>
                                                <td><b>Fecha</b></td>
                                                <td><b>S. Anterior</b></td>
                                                <td><b>V. Bruta</b></td>
                                                <td><b>Neto</b></td>
                                                {/*<td><b>Cobros a Vendedores</b></td>*/}
                                                <td><b>Cobro a Prestamos</b></td>
                                                {/*<td><b>Abonos</b></td>*/}
                                                <td><b>Premios</b></td>
                                                <td><b>Ingresos</b></td>
                                                <td><b>Egresos</b></td>
                                                <td><b>Total</b></td>
                                                <td><i className="bi bi-calendar2-plus"></i></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Array.isArray(cartera) ? cartera.map((item, index) => (
                                            <tr className='text-center align-items-center ' style={{fontSize : '12px'}} key={index}>
                                                <td>{item.id_cartera}</td>
                                                <td className="text-center"><span className="badge text-bg-dark">{item.fecha}</span></td>
                                                <td className={item.saldo_anterior > 0 ? 'text-danger' : 'text-success'}>{formatearMonedaValue(item.saldo_anterior)}</td>
                                                <td>{formatearMonedaValue(item.venta_bruta)}</td>


                                                { props.data_sesion.rol_id === 2 ?
                                                    <td className='text-danger'>{formatearMonedaValue(item.venta_bruta -  (item.venta_neta_promotor + item.venta_neta_vendedor))}</td>
                                                :''}

                                                { props.data_sesion.rol_id === 1 ?
                                                    <td className='text-success'>{formatearMonedaValue(item.venta_bruta - (item.venta_neta_promotor + item.venta_neta_vendedor + item.venta_neta_admin_externo ))}</td>
                                                :''}

                                                {/*<td className='text-danger'>{formatearMonedaValue(item.cobros_prestamo_vendedor)}</td>*/}
                                                
                                                { props.data_sesion.tipo_comercio === 'ADMIN-EXTERNO' ?
                                                    <td className='text-danger'>{formatearMonedaValue(item.cobros_prestamo_admin_externo)}</td>:
                                                  props.data_sesion.tipo_comercio === 'PROMOTOR' ? 
                                                    <td className='text-danger'>{formatearMonedaValue(item.cobros_prestamo_promotor)}</td>: 
                                                    <td className='text-danger'>{formatearMonedaValue(item.cobros_prestamo_vendedor)}</td>
                                                }
                                                {/*<td className='text-success'>{formatearMonedaValue(item.abonos)}</td>*/}
                                                <td className='text-success'>{formatearMonedaValue(item.premios)}</td>
                                                <td className='text-success'>{formatearMonedaValue(item.ingresos)}</td>
                                                <td className='text-danger'>{formatearMonedaValue(item.egresos)}</td>
                                                <td className={item.saldo_actual > 0 ? 'text-danger' : 'text-success'}>{formatearMonedaValue(item.saldo_actual)}</td>
                                                <td>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-outline-dark btn-sm " 
                                                        title="Movimientos"
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#modalMovimientos"
                                                        data-id={item.comercio_id}
                                                        onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}                                                        
                                                        >
                                                        <i 
                                                            className="bi bi-calendar2-plus"
                                                            data-id={item.comercio_id}
                                                            onClick={(e) => handleComercioSeleccionado(e.target.getAttribute('data-id'))}
                                                        >
                                                        </i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )) : <tr></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    :''}
                    {spiner ?
                    <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                    :''}
                    {verMnesaje ?
                    <div className='text-center text-secondary my-4'>
                        <h3>Sin resultado de busqueda</h3>
                        <p>Por favor, elija un rango de fecha para buscar registros de cartera</p>
                    </div>
                    :''}
                </section>
            </main>
            <Movimientos></Movimientos>
        </React.Fragment>
    );
}


const mapDispatchToProps  = {
    setIdComercio,
    setIdComercioSeleccionado
};

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
    }
}

export default connect(mapSateToProps, mapDispatchToProps)(MiCartera)
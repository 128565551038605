import InstaceAxios  from "./Api";

export const getRifa = async (id_rifa = false) => {
    const result = await InstaceAxios.get(`rifa/rifa?id_rifa=${id_rifa}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const eliminarRifa = async (id_rifa) => {
    const result = await InstaceAxios.delete(`rifa/eliminar?id_rifa=${id_rifa}`)
        .then(res => res)
        .catch(error => error)
    return result
}
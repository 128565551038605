import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setTablaLoteria, setLoteriasHoy } from '../../../../actions'
import { getLoterias, getHoy } from '../../../../services/Loterias';
import { alerta } from '../../../../services/utilities';
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import HeaderModulo from "../../../header/HeaderModulo";
import Tabla from './Tabla'
import Hoy from './Hoy'
import Nueva from './Nueva'
import Editar from './Editar';

function Loterias(props){

    useEffect(() => {

        getLoterias().then(response => {
            if(response.status === 200){
                props.setTablaLoteria(response.data)
            }else{
                alerta('No se cargo la tabla de loterias o no hay loterias actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });

        getHoy().then(response => {
            if(response.status === 200){
                props.setLoteriasHoy(response.data)
            }else{
                alerta('No se cargaron los resultados de hoy',response.response.data.status_message??response.message,'warning');
            }
        });
    
    }, []);

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    { parseInt(props.data_sesion.rol_id) === 0 ?
                                    <div  className="mb-4" style={{ textAlign : 'right' }}>
                                        <button type="button" className="btn btn-primary mt-3" data-bs-toggle="modal" data-bs-target="#modalNuevaLoteria">Crear una nueva loteria</button>
                                    </div>
                                    :''}
                                    <Tabla></Tabla>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <Hoy></Hoy>
                        </div>
                    </div>
                </section>
            </main>
            <Nueva></Nueva>
            <Editar rol_id={props.data_sesion.rol_id}></Editar>
        </React.Fragment>
    );
}


const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion
    }
}

const mapDispatchToProps  = {
    setTablaLoteria,
    setLoteriasHoy
};

export default connect(mapSateToProps, mapDispatchToProps)(Loterias)
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setIdBloqueo } from '../../../../actions'

function Tabla(props){

    useEffect(() => {
        setBloqueos(props.bloqueos.data??[]);
     },[props.bloqueos.data]);

    const [filter, setFilter] = useState('');
    const [bloqueos, setBloqueos] = useState([]);
    const filteredData = bloqueos.filter((item) => {

        const loteriaMatch = item.loteria.toLowerCase().includes(filter.toLowerCase());
        const modalidadMatch = item.modalidad.toLowerCase().includes(filter.toLowerCase());
        const tipoMatch = item.tipo_bloqueo.toLowerCase().includes(filter.toLowerCase());
        const valorTipoBloqueoMatch = item.valor_tipo_bloqueo.toLowerCase().includes(filter.toLowerCase());
        const valorMatch = item.valor_venta.toString().includes(filter);
        return loteriaMatch || valorTipoBloqueoMatch || modalidadMatch || tipoMatch || valorMatch;

    });
    
    return(
        <React.Fragment>
            
            <div className="card">
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-3 pt-4'>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                <input type="text" className="form-control form-control-sm" placeholder="Buscar..." aria-label="Buscar" aria-describedby="basic-addon1" value={filter}
                                onChange={(e) => setFilter(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-2 offset-md-7 align-self-end pt-4">
                            <div style={{ textAlign : 'right' }}>
                                <button 
                                    type="button" 
                                    className="btn btn-primary"
                                    data-bs-toggle="modal" 
                                    data-bs-target="#modalCrearBloqueo"
                                >
                                    Crear Bloqueo
                                </button>
                            </div>               
                        </div>
                        <div className='col-md-12'>
                            <div className='table-container'>
                                <div className="table-responsive">
                                    <table className='table table-hover'>
                                        <thead>
                                            <tr className="text-center">
                                                <th>Loteria</th>
                                                <th>Modalidad</th>
                                                <th>Tipo Bloqueo</th>
                                                <th>Cifras / Numero</th>
                                                <th>Combinado</th>
                                                <th>$ Valor</th>
                                                {props.data_sesion.venta_cubierta === 1 ?
                                                    <th>$ Valor Cubierta</th>
                                                :''}
                                                <th>Estado</th>
                                                <th>Ver</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {filteredData.map((bloqueo, index) => (
                                            <tr className="text-center" key={index}>
                                                <td>{bloqueo.loteria}</td>
                                                <td className={bloqueo.modalidad === 'APUESTA' ? 'text-success' : bloqueo.modalidad === 'QUINTA' ? 'text-info' : 'text-primary' }><b>{bloqueo.modalidad}</b></td>
                                                <td>
                                                    <span className={bloqueo.tipo_bloqueo == 'cifras' ? 'badge rounded-pill text-bg-warning m-1' : 'badge rounded-pill text-bg-primary m-1' } key={index}>
                                                        {bloqueo.tipo_bloqueo.toLowerCase()}
                                                    </span>                    
                                                </td>
                                                <td><b>{bloqueo.valor_tipo_bloqueo}</b></td>
                                                <td><b>{bloqueo.modalidad === 'APUESTA' ? bloqueo.combinado === 1 ? 'SI' : 'NO' : 'NO APLICA'}</b></td>
                                                <td>
                                                    {Math.floor(bloqueo.valor_venta).toLocaleString('es-CO', { style: 'currency', currency: 'COP', maximumFractionDigits: 0 })}
                                                </td>
                                                {props.data_sesion.venta_cubierta === 1 ?
                                                    <td>{Math.floor(bloqueo.valor_cubierta).toLocaleString('es-CO', { style: 'currency', currency: 'COP', maximumFractionDigits: 0 })}</td>
                                                :''}
                                                <td>
                                                    {bloqueo.estado_id == '1' ?<p className='text-success'>Activo</p> : <p className='text-danger'>Inactivo</p>}
                                                </td>
                                                <td>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-outline-primary btn-sm " 
                                                        title="Ver Loteria" 
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#modalEditarBloqueo"
                                                        onClick={() => props.setIdBloqueo(bloqueo.id_bloqueo)}
                                                        >
                                                        <i className="bi bi-gear"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {
    return {
        bloqueos: state.bloqueos,
        data_sesion: state.data_sesion
    }
}

const mapDispatchToProps  = {
    setIdBloqueo
};

export default connect(mapSateToProps, mapDispatchToProps)(Tabla)
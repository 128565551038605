
import Swal  from 'sweetalert2';

export const alerta = (title='! Alerta ¡', text='Se presento un error', icon='error') =>{
  Swal.fire(
    title,
    text,
    icon
  )
}

export const toast = (title='! Alerta ¡', text='Se presento un error', icon='error') =>{

  Swal.fire({
    toast: true,
    position: 'bottom-right',
    title: title,
    html: '<p style="font-size:15px">' + text + '</p>',
    icon: icon,
    showCancelButton: false,
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    width: 400
});

}


export const cerrarModal = (id)  =>{

  let modal = document.getElementById(id);
  modal.style.display = 'none';
  let backdrop = document.querySelector('.modal-backdrop');
  if (backdrop) {
      backdrop.parentNode.removeChild(backdrop);
  }

  document.body.style.paddingRight = '';
  document.body.style.overflow = '';

}


export const formatearMoneda = (input) =>{

  const valor = parseFloat(input.value);
  
  if (!isNaN(valor)) { 
    input.value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
  }
}


export const validarEmail = (email) => {

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
  
};

export const currentDate = () => {

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Agregar 1 al mes ya que los meses se indexan desde 0
  const day = String(currentDate.getDate()).padStart(2, '0');
  return  `${year}-${month}-${day}`;
  
};


export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatearMonedaValue = (value) =>{

  const valor = parseFloat(value);
  
  if (!isNaN(valor)) { 
    value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
  }else{
      value = 0;
  }

  return value;

}



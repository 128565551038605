import React from 'react';
import { connect } from 'react-redux';
import { setIdZona } from '../../../../actions'

function Lista (props){

    const handleZonaSeleccionada = (id_zona) => {
        props.setIdZona(id_zona);
    } 
    
    return(
        <React.Fragment>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Lista de zonas creadas:</h5>
                        <table className="table table-hover table-sm">
                            <thead>
                                <tr>
                                    <th>Zona</th>
                                    <th className="text-center"># Loterias</th>
                                    <th className="text-center">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                            {Array.isArray(props.listaZonas) ? props.listaZonas.map((zona, index) => (
                                <tr key={index}>
                                    <td>{zona.nombre}</td>
                                    <td className="text-center">{zona.cantidad}</td>
                                    <td className="text-center">
                                        <button 
                                            type="button" 
                                            className="btn btn-outline-primary btn-sm " 
                                            title="Ver Loteria" 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#modalEditarZona"
                                            onClick={() => handleZonaSeleccionada(zona.id_zona)}
                                            >
                                            <i className="bi bi-gear"></i>
                                        </button>
                                    </td>
                                </tr>
                            )) : <tr></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

const mapSateToProps = state => {

    return {
        listaZonas: state.listaZonas
    }
}

const mapDispatchToProps  = {
    setIdZona
};



export default  connect(mapSateToProps, mapDispatchToProps)(Lista);
import React, {useState} from 'react';
import { connect } from 'react-redux';
import Header from "../../header/Header";
import HeaderModulo from "../../header/HeaderModulo";
import Aside from "../../aside/Aside";
import { alerta, toast, formatDate } from '../../../services/utilities';
import { getReporteCubiertaExtracto, getReporteCubierta, getCartera } from '../../../services/Reportes';

function Cubierta(props){

    const [listaVentas, setlistaVentas] = useState([]);
    const [spinerTabla, setSpinerTabla] = useState(false);
    const [verMensaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(false);
    const [filterTabla, setFilterTabla] = useState('');

    const [listaVentasCubierta, setlistaVentasCubierta] = useState([]);
    const [spinerTablaCubierta, setSpinerTablaCubierta] = useState(false);
    const [verMensajeCubierta, setVerMensajeCubierta] = useState(true);
    const [verTableCubierta, setVerTableCubierta] = useState(false);
    const [filterTablaCubierta, setFilterTablaCubierta] = useState('');

    const [listaVentasCartera, setlistaVentasCartera] = useState([]);
    const [spinerTablaCartera, setSpinerTablaCartera] = useState(false);
    const [verMensajeCartera, setVerMensajeCartera] = useState(true);
    const [verTableCartera, setVerTableCartera] = useState(false);
    const [filterTablaCartera, setFilterTablaCartera] = useState('');

    const [fechaFin, setFechaFin] = useState(false);

    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date())
    });

    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const handleBuscarReporteCubierta = () => {
        handleConsultarExtractoCubierta();
        handleConsultarCubierta();
        handleConsultarCartera();
    }


    const handleConsultarExtractoCubierta = async () => {

        setVerMensaje(false);
        setSpinerTabla(true);
        setVerTable(false);

        try {
            await getReporteCubiertaExtracto(filtro.fecha_ini).then( response => {

                if(response.status !== undefined && response.status === 200){

                    if(response.data.data.length > 0){
                        setlistaVentas(response.data.data);
                        setVerTable(true);
                    }else{
                        setVerMensaje(true)
                        setVerTable(false);
                    }

                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de ventas  (reportes/cubierta/handleConsultarExtractoCubierta) ->'+ error);
        }finally {
            setSpinerTabla(false);
        }
    }

    const handleConsultarCubierta = async () => {

        setVerMensajeCubierta(false);
        setSpinerTablaCubierta(true);
        setVerTableCubierta(false);

        try {
            await getReporteCubierta(filtro.fecha_ini).then( response => {

                if(response.status !== undefined && response.status === 200){
                    if(response.data.data.length > 0){
                        setlistaVentasCubierta(response.data.data);
                        setVerTableCubierta(true);
                    }else{
                        setVerMensajeCubierta(true)
                        setVerTableCubierta(false);
                    }

                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de ventas  (reportes/cubierta/handleConsultarCubierta) ->'+ error);
        }finally {
            setSpinerTablaCubierta(false);
        }
    }

    const handleConsultarCartera = async () => {

        setVerMensajeCartera(false);
        setSpinerTablaCartera(true);
        setVerTableCartera(false);

        try {
            await getCartera(filtro.fecha_ini, filtro.fecha_fin, props.data_sesion.comercio_cubierta_id).then( response => {

                if(response.status !== undefined && response.status === 200){
                    if(response.data.cartera.length > 0){
                        setlistaVentasCartera(response.data.cartera);
                        setVerTableCartera(true);
                    }else{
                        setVerMensajeCartera(true)
                        setVerTableCartera(false);
                    }

                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de ventas  (reportes/cubierta/handleConsultarCartera) ->'+ error);
        }finally {
            setSpinerTablaCartera(false);
        }
    }

    const filterDataTabla = listaVentas.filter((item) => {

        const idMatch =  item.id_transaccion !== null && item.id_transaccion ?  item.id_transaccion.toString().includes(filterTabla) : '';
        const EstadoMatch = item.estado !== null && item.estado  ? item.estado.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const NumeroMatch = item.numero !== null && item.numero  ? item.numero.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const LoteriaMatch = item.loteria !== null && item.loteria ? item.loteria.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const ModalidadMatch = item.modalidad !== null && item.modalidad ? item.modalidad.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const ProductoMatch = item.producto !== null && item.producto  ?  item.producto.toString().includes(filterTabla) : '';
        const valorMatch = item.valor !== null && item.valor  ?  item.valor.toString().includes(filterTabla) : '';
        return idMatch || NumeroMatch || LoteriaMatch || ProductoMatch || valorMatch || ModalidadMatch || EstadoMatch;

    });

    const filterDataTablaCubierta = listaVentasCubierta.filter((item) => {

        const NumeroMatch = item.numero !== null && item.numero  ? item.numero.toLowerCase().includes(filterTablaCubierta.toLowerCase()) : '';
        const LoteriaMatch = item.loteria !== null && item.loteria ? item.loteria.toLowerCase().includes(filterTablaCubierta.toLowerCase()) : '';
        const ModalidadMatch = item.modalidad !== null && item.modalidad ? item.modalidad.toLowerCase().includes(filterTablaCubierta.toLowerCase()) : '';
        const ProductoMatch = item.producto !== null && item.producto  ?  item.producto.toString().includes(filterTablaCubierta) : '';
        const valorMatch = item.valor !== null && item.valor  ?  item.valor.toString().includes(filterTablaCubierta) : '';
        return NumeroMatch || LoteriaMatch || ProductoMatch || valorMatch || ModalidadMatch;

    });

    var total_extracto = 0;
    var total_cubierta = 0;

    if(Array.isArray(listaVentas) ){
        filterDataTabla.map((listaVentas) => {
            total_extracto = total_extracto  + listaVentas.valor;
        });
    }

    if(Array.isArray(listaVentasCubierta) ){
        filterDataTablaCubierta.map((listaVentasCubierta) => {
            total_cubierta = total_cubierta  + listaVentasCubierta.valor;
        });
    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className='row'>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Fecha:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_ini" 
                                    name="fecha_ini" 
                                    value={filtro.fecha_ini}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        { fechaFin ?
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Hasta:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_fin" 
                                    name="fecha_fin" 
                                    value={filtro.fecha_fin}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div> :''}                     
                        <div className="col-md-1">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary btn-md"
                                    onClick={handleBuscarReporteCubierta}
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>

                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button onClick={() => setFechaFin(false)} className="nav-link active" id="bnt-nav-1" data-bs-toggle="tab" data-bs-target="#cubierta-tab" type="button" role="tab" aria-controls="cubierta-tab-aria" aria-selected="true">Cubierta</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => setFechaFin(false)} className="nav-link" id="bnt-nav-2" data-bs-toggle="tab" data-bs-target="#extracto-tab" type="button" role="tab" aria-controls="extracto-tab-aria" aria-selected="false">Extracto</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => setFechaFin(true)} className="nav-link" id="bnt-nav-3" data-bs-toggle="tab" data-bs-target="#cartera-tab" type="button" role="tab" aria-controls="cartera-tab-aria" aria-selected="false">Cartera</button>
                        </li>                      
                    </ul>
                    
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="cubierta-tab" role="tabpanel" aria-labelledby="cubierta-tab-aria">
                            {verTableCubierta ?
                                <div className="card">
                                    <div className="card-body">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className="col-md-3 mt-3">                            
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                                    <input 
                                                        type="text" 
                                                        className="form-control form-control-sm" 
                                                        placeholder="Buscar..." 
                                                        aria-label="Buscar" 
                                                        aria-describedby="basic-addon1" 
                                                        value={filterTablaCubierta}
                                                        onChange={(e) => setFilterTablaCubierta(e.target.value)} 
                                                    />
                                                </div>
                                            </div>
                                            <p className='text-secondary m-0' style={{fontSize :'12px'}}>Total Registros {filterDataTablaCubierta.length}</p>
                                        </div>
                                        <div className='table-container'>
                                            <div className="table-responsive-md">
                                                <table className='table table-hover' style={{fontSize :'12px'}}>
                                                    <thead>
                                                        <tr>
                                                            <th>Empresa</th>
                                                            <th>Loteria</th>
                                                            <th className='text-center'>Numero</th>
                                                            <th>Valor</th>
                                                            <th className='text-center'>Producto</th>
                                                            <th>Modalidad</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {Array.isArray(listaVentasCubierta) ? filterDataTablaCubierta.map((venta, index) => (
                                                        <tr key={index}>
                                                            <td>{venta.empresa}</td>  
                                                            <td>{venta.loteria}</td>  
                                                            <td className='text-center'>{venta.numero}</td>
                                                            <td>${formatearMoneda(venta.valor)}</td>
                                                            <td className={venta.producto === 'APUESTA' ? 'text-success text-center' : venta.producto === 'QUINTA' ? 'text-info text-center' : 'text-primary text-center'}><b>{venta.producto}</b></td>
                                                            <td>{venta.modalidad}</td>
                                                        </tr>
                                                    )) : <tr></tr>}
                                                    <tr className='table-success'>
                                                        <td colSpan="3" className='text-end'>
                                                            <b>Total</b>
                                                        </td>
                                                        <td colSpan="3">
                                                            <b>$ {formatearMoneda(total_cubierta)}</b>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :''}
                            {spinerTablaCubierta ?
                            <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                                <div className="spinner-border" role="status"></div>                 
                            </div>
                            :''}
                            {verMensajeCubierta ?
                            <div className='text-center text-secondary my-4'>
                                <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                                <p>Por favor elija una combinación de filtros diferente.</p>
                            </div>
                            :''}

                        </div>
                        <div className="tab-pane fade" id="extracto-tab" role="tabpanel" aria-labelledby="extracto-tab">
                            {verTable ?
                                <div className="card">
                                    <div className="card-body">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className="col-md-3 mt-3">                            
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                                    <input 
                                                        type="text" 
                                                        className="form-control form-control-sm" 
                                                        placeholder="Buscar..." 
                                                        aria-label="Buscar" 
                                                        aria-describedby="basic-addon1" 
                                                        value={filterTabla}
                                                        onChange={(e) => setFilterTabla(e.target.value)} 
                                                    />
                                                </div>
                                            </div>
                                            <p className='text-secondary m-0' style={{fontSize :'12px'}}>Total Registros {filterDataTabla.length}</p>
                                        </div>
                                        <div className='table-container'>
                                            <div className="table-responsive-md">
                                                <table className='table table-hover' style={{fontSize :'12px'}}>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>Id</th>
                                                            <th>Empresa</th>
                                                            <th className='text-center'>Hora</th>
                                                            <th className='text-center'>Estado</th>
                                                            <th className='text-center'>Numero</th>
                                                            <th className='text-center'>Valor</th>
                                                            <th className='text-center'>Loteria</th>
                                                            <th className='text-center'>Producto</th>
                                                            <th>Modalidad</th>
                                                            <th><i className="bi bi-clipboard-heart"></i>Observacion</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {Array.isArray(listaVentas) ? filterDataTabla.map((venta, index) => (
                                                        <tr key={index}>
                                                            <td><span className="badge text-bg-warning"># {venta.id_transaccion}</span></td>
                                                            <td>{venta.empresa}</td>
                                                            <td className='text-center'><span className="badge text-bg-dark">{venta.hora}</span></td>            
                                                            <td className='text-center'><span className={venta.estado === 'APROBADO' ?'badge text-bg-success':'badge text-bg-danger'}>{venta.estado}</span></td>
                                                            <td className='text-center'>{venta.numero}</td>
                                                            <td>${formatearMoneda(venta.valor)}</td>
                                                            <td>{venta.loteria}</td>
                                                            <td className={venta.producto === 'APUESTA' ? 'text-success text-center' : venta.producto === 'QUINTA' ? 'text-info text-center' : 'text-primary text-center'}><b>{venta.producto}</b></td>
                                                            <td>{venta.modalidad}</td>
                                                            <td style={{fontSize : '10px'}}>{venta.observacion}</td>
                                                        </tr>
                                                    )) : <tr></tr>}
                                                    <tr className='table-success'>
                                                        <td colSpan="5" className='text-end'>
                                                            <b>Total:</b>
                                                        </td>
                                                        <td colSpan="5">
                                                            <b>${formatearMoneda(total_extracto)}</b>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :''}
                            {spinerTabla ?
                            <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                                <div className="spinner-border" role="status"></div>                 
                            </div>
                            :''}
                            {verMensaje ?
                            <div className='text-center text-secondary my-4'>
                                <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                                <p>Por favor elija una combinación de filtros diferente.</p>
                            </div>
                            :''}
                        </div>
                        <div className="tab-pane fade" id="cartera-tab" role="tabpanel" aria-labelledby="cartera-tab">
                            
                            {verTableCartera ?
                                <div className='table-container'>
                                    <div className="table-responsive-md">
                                        <table className='table table-hover' style={{fontSize :'12px'}}>
                                            <thead>
                                                <tr>
                                                    <th>Fecha</th>
                                                    <th>V. Bruta</th>
                                                    <th>Ganancia</th>
                                                    <th>Saldo Anterior</th>
                                                    <th>V Neta</th>
                                                    <th>Premios</th>
                                                    <th>Ingresos</th>
                                                    <th>Egresos</th>
                                                    <th>Saldo Actual</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(listaVentasCartera) ? listaVentasCartera.map((item, index) => (
                                                    <tr key={index} style={{fontWeight :'bold'}}>
                                                        <td><span className="badge text-bg-dark">{item.fecha}</span></td>
                                                        <td className='text-secondary'>$ {formatearMoneda(item.venta_bruta)}</td>
                                                        <td className='text-secondary'>$ {formatearMoneda(item.venta_neta_vendedor)}</td>
                                                        <td>$ {formatearMoneda(item.saldo_anterior)}</td>
                                                        <td>$ {formatearMoneda(item.venta_bruta  - item.venta_neta_vendedor)}</td>
                                                        <td className='text-success'>$ {formatearMoneda(item.premios)}</td>
                                                        <td className='text-success'>$ {formatearMoneda(item.ingresos)}</td>
                                                        <td className='text-danger'>$ {formatearMoneda(item.egresos)}</td>
                                                        <td className={ item.saldo_actual <= 0 ? 'text-success' : 'text-danger' }>$ {formatearMoneda(item.saldo_actual)}</td>
                                                    </tr>
                                                )) : <tr></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            :''}

                            {spinerTablaCubierta ?
                                <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                                    <div className="spinner-border" role="status"></div>                 
                                </div>
                            :''}
                            {verMensajeCubierta ?
                                <div className='text-center text-secondary my-4'>
                                    <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                                    <p>Por favor elija una combinación de filtros diferente.</p>
                                </div>
                            :''}
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    );

}

const mapSateToProps = state => {

    return {
        data_usuario: state.data_usuario,
        data_sesion: state.data_sesion
    }
}

export default connect(mapSateToProps, null)(Cubierta)
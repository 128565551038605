import React, { useState } from 'react';
import Swal  from 'sweetalert2';
import { alerta, toast, formatearMoneda } from '../../../../services/utilities';
import { connect } from 'react-redux';
import { setListaRifas } from '../../../../actions'
import { crearRifa } from '../../../../services/RifaFile';
import { getRifa } from '../../../../services/Rifa';

function Nueva(props){

    const [rifa, setRifa] = useState({
        "cantidad_cifras":"",
        "cantidad_ganadores": 1,
        "valor_venta":"",
        "valor_premio_rifa":"",
        "fecha_termina":"",
        "descripcion":"",
        "estado_id": 8,
        "loteria_id":"",
        "img":""
    });
    const [imagePreview, setImagePreview] = useState(null);

    const handleInputRifaChange = (event) => {
        const { name, value } = event.target;
        setRifa({ ...rifa, [name]: value });
    };

    const handleImageChange = (e) => {

        const file = e.target.files[0];
    
        if(file){

            if(!file.type.startsWith('image/')) {
                alerta('El archivo seleccionado no es una imagen válida.');
                return;
            }
            const reader = new FileReader();
    
            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };
    
            reader.readAsDataURL(file);

            setRifa({ ...rifa, img: e.target.files[0] })       
        }
    };

    const handleValidarCampos = () => {

        let msg = false;

        const currentDate = new Date();
        const selectedDate = new Date(rifa.fecha_termina)

        if(rifa.descripcion == ''){
            msg = 'Debe de colocar una descripcion para la rifa';
        }else if(rifa.valor_premio_rifa <= 0){
            msg = 'El valor del premio es requerido';
        }else if(rifa.cantidad_cifras < 1 || rifa.cantidad_cifras > 4){
            msg = 'La cantidad de cifras tiene que ser entre 1 y 4';
        }else if(selectedDate <= currentDate ){
            msg = 'La fecha de finalizacion no es valida';
        }else if(rifa.loteria_id == ''){
            msg = 'Debe seleccionar una loteria';
        }
            
        return msg;
    }

    const handleRefrescarListaRifa = () => {

        getRifa().then(response => {
            if(response.status === 200){
                props.setListaRifas(response.data.data)
            }else{
                alerta('No se cargo la tabla de loterias o no hay loterias actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });

    }

    const handleGuardarRifa = () => {

        if(handleValidarCampos()){
            alerta(handleValidarCampos());
            return;
        }

        const formData = new FormData();

        formData.append('cantidad_cifras', rifa.cantidad_cifras);
        formData.append('cantidad_ganadores', rifa.cantidad_ganadores);        
        formData.append('valor_venta', rifa.valor_venta);
        formData.append('valor_premio_rifa', rifa.valor_premio_rifa);
        formData.append('fecha_termina', rifa.fecha_termina);
        formData.append('descripcion', rifa.descripcion);
        formData.append('estado_id', rifa.estado_id);
        formData.append('loteria_id', rifa.loteria_id);
        formData.append('img', rifa.img);

        Swal.fire({
            title: "¿ Esta seguro de guardar la información de la rifa ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                crearRifa(formData).then(response => {
                    if(response.status == 201){
                        setImagePreview(false);
                        handleRefrescarListaRifa();
                        setRifa({
                            "cantidad_cifras":"",
                            "cantidad_ganadores":1,
                            "valor_venta":"",
                            "valor_premio_rifa":"",
                            "fecha_termina":"",
                            "descripcion":"",
                            "estado_id": 1,
                            "loteria_id":"",
                            "img":""
                        });
                        toast('¡ Perfecto !','Rifa creada exitosamente','success');
                    }else{
                        alerta('Se presento un error al intentar guardar la rifa',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }
    
    return(
        <React.Fragment>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Nueva <span>| Rifa</span></h5>
                        <form encType="multipart/form-data">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="descripcion" 
                                            name="descripcion" 
                                            value={rifa.descripcion} 
                                            onChange={handleInputRifaChange} 
                                        />
                                        <label htmlFor="floatingName">¿Que se esta rifando? <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="number" 
                                            className="form-control" 
                                            id="valor_premio_rifa" 
                                            name="valor_premio_rifa" 
                                            value={rifa.valor_premio_rifa}
                                            onChange={handleInputRifaChange}
                                        />
                                        <label htmlFor="floatingName">Valor del premio: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="number" 
                                            className="form-control" 
                                            id="valor_venta" 
                                            name="valor_venta" 
                                            value={rifa.valor_venta}
                                            onChange={handleInputRifaChange}
                                        />
                                        <label htmlFor="floatingName">Valor de la boleta: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <select 
                                            className="form-select" 
                                            id="loteria_id" 
                                            name="loteria_id" 
                                            aria-label="State" 
                                            value={rifa.loteria_id}
                                            onChange={handleInputRifaChange}
                                        >
                                            <option value="">-- Seleccion --</option>
                                            {props.tableLoterias.data != undefined ? props.tableLoterias.data.map(opcion => (
                                                <option key={opcion.id_loteria} value={opcion.id_loteria}>{opcion.prefijo}</option>
                                            )):''}
                                        </select>
                                        <label htmlFor="id_loteria_sugerida">Loteira: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="number"
                                            className="form-control"
                                            maxLength="1"
                                            min="1"
                                            max="4"
                                            id="cantidad_cifras"
                                            name="cantidad_cifras"
                                            value={rifa.cantidad_cifras}
                                            onChange={handleInputRifaChange}
                                        />
                                        <label htmlFor="floatingName"># cifras: <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="number"
                                            className="form-control"
                                            maxLength="1"
                                            min="1"
                                            max="4"
                                            id="cantidad_ganadores"
                                            name="cantidad_ganadores"
                                            value={rifa.cantidad_ganadores}
                                            onChange={handleInputRifaChange}
                                        />
                                        <label htmlFor="floatingName"># ganadores:</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="datetime-local" 
                                            className="form-control" 
                                            id="fecha_termina" 
                                            name="fecha_termina" 
                                            value={rifa.fecha_termina} 
                                            onChange={handleInputRifaChange} 
                                        />
                                        <label htmlFor="floatingName">¿Cuando finaliza la rifa? <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <select 
                                            className="form-select" 
                                            id="estado_id" 
                                            name="estado_id" 
                                            aria-label="State"
                                            value={rifa.estado_id}
                                            onChange={handleInputRifaChange}
                                        >
                                            <option value="8">Habilitada</option>
                                            <option value="9">Cerrada</option>
                                        </select>
                                        <label htmlFor="id_loteria_sugerida">Estado</label>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div>
                                        <label htmlFor="img" className="form-label">Imagen Rifa:</label>
                                        <input 
                                            className="form-control form-control-lg" 
                                            id="img"
                                            name="img"
                                            type="file" 
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    {imagePreview && (
                                        <img
                                        src={imagePreview}
                                        alt="Preview de la Imagen"
                                        style={{ maxWidth: '100%', maxHeight: '300px' }}
                                        />
                                    )}
                                </div>
                                <div className="col-md-12 pt-4">
                                    <button type="button" className="btn btn-primary" onClick={handleGuardarRifa}>
                                        Crear Nueva Rifa
                                    </button>
                                </div>
                            </div>  
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


const mapSateToProps = state => {

    return {
        tableLoterias: state.tableLoterias
    }
}

const mapDispatchToProps  = {
    setListaRifas,
};

export default connect(mapSateToProps, mapDispatchToProps)(Nueva)
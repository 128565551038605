import InstaceAxios  from "./Api";

export const guardarBloqueo = async (data) => {
    const result = await InstaceAxios.post('bloqueo/crear', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const editarBloqueo = async (data) => {
    const result = await InstaceAxios.post('bloqueo/editar', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const eliminarBloqueo = async (id_bloqueo) => {
    const result = await InstaceAxios.delete(`bloqueo/eliminar?id_bloqueo=${id_bloqueo}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getBloqueo = async (id_bloqueo) => {
    const result = await InstaceAxios.get(`bloqueo/bloqueos?id_bloqueo=${id_bloqueo}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getBloqueos = async () => {
    const result = await InstaceAxios.get(`bloqueo/bloqueos`)
        .then(res => res)
        .catch(error => error)
    return result
}
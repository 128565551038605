import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Header from "../../header/Header";
import HeaderModulo from "../../header/HeaderModulo";
import Aside from "../../aside/Aside";
import { alerta, toast, formatDate } from '../../../services/utilities';
import { getReporteLiquidacion } from '../../../services/Reportes';
import DetalleVenta from './DetalleVenta';
import { setIdTransaccion} from '../../../actions'

function Liquidacion(props){

    const [spinerTabla, setSpinerTabla] = useState(false);
    const [verMnesaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(false);
    const [listaLiquidacion, setlistaLiquidacion] = useState([]);
    const [filterTabla, setFilterTabla] = useState('');

    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date()),
        "tipo_comercio":""
    });

    const filterDataTabla = listaLiquidacion.filter((item) => {

        const vendedorMatch = item.comercio !== null && item.comercio  ? item.comercio.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const idComercioMatch = item.id_comercio !== null && item.id_comercio  ?  item.id_comercio.toString().includes(filterTabla) : '';
        return vendedorMatch || idComercioMatch;

    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }

    const handleBuscarLiquidacion = async () => {

        setVerMensaje(false);
        setSpinerTabla(true);
        setVerTable(false);

        try {
            await getReporteLiquidacion(filtro.fecha_ini, filtro.fecha_fin, filtro.tipo_comercio).then( response => {

                if(response.status !== undefined && response.status === 200){
                    if(response.data.liquidacion.length > 0){
                        setlistaLiquidacion(response.data.liquidacion);
                        setVerTable(true);
                    }else{
                        setVerMensaje(true)
                        setVerTable(false);
                    }

                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de liquidacion  (reportes/ventaDetallada/handleBuscarLiquidacion) ->'+ error);
        }finally {
            setSpinerTabla(false);
        }
    }

    var total_v_brutar = 0;
    var total_neto = 0;
    var total_cobro_prestamo = 0;
    var total_abono_prestamo = 0;
    var total_premios = 0;
    var total_ingresos = 0;
    var total_egresos = 0;
    var total_total = 0;

    if(Array.isArray(listaLiquidacion) ){

        filterDataTabla.map((listaLiquidacion) => {

            total_v_brutar = total_v_brutar + listaLiquidacion.venta_bruta;

            if(filtro.tipo_comercio === 'VENDEDOR'){
                total_neto = total_neto + (listaLiquidacion.venta_bruta - listaLiquidacion.venta_neta_vendedor);
                total_cobro_prestamo = total_cobro_prestamo + listaLiquidacion.cobros_prestamo_vendedor;
                total_total = total_total + ((listaLiquidacion.venta_bruta + listaLiquidacion.cobros_prestamo_vendedor) - (listaLiquidacion.venta_neta_vendedor + listaLiquidacion.premios));
            }

            if(filtro.tipo_comercio === 'PROMOTOR'){
                total_neto = total_neto + (listaLiquidacion.venta_bruta - (listaLiquidacion.venta_neta_promotor + listaLiquidacion.venta_neta_vendedor));
                total_cobro_prestamo = total_cobro_prestamo + listaLiquidacion.cobros_prestamo_promotor;
                total_total = total_total + ((listaLiquidacion.venta_bruta + listaLiquidacion.cobros_prestamo_promotor) - (listaLiquidacion.venta_neta_promotor + listaLiquidacion.venta_neta_vendedor + listaLiquidacion.premios));
            }

            if(filtro.tipo_comercio === 'ADMIN-EXTERNO'){
                total_neto = total_neto + (listaLiquidacion.venta_bruta - (listaLiquidacion.venta_neta_promotor + listaLiquidacion.venta_neta_vendedor +listaLiquidacion.venta_neta_admin_externo));
                total_cobro_prestamo = total_cobro_prestamo + listaLiquidacion.cobros_prestamo_admin_externo;
                total_total = total_total + ((listaLiquidacion.venta_bruta + listaLiquidacion.cobros_prestamo_admin_externo) - (listaLiquidacion.venta_neta_promotor + listaLiquidacion.venta_neta_vendedor + listaLiquidacion.venta_neta_admin_externo + listaLiquidacion.premios));
            }

            total_abono_prestamo = total_abono_prestamo + listaLiquidacion.abonos;
            total_premios = total_premios + listaLiquidacion.premios;
            total_ingresos = total_ingresos + listaLiquidacion.ingresos;
            total_egresos = total_egresos + listaLiquidacion.egresos;

        });

    }
    
    const handleArmarTablaSubLiquidacion = (array_sub_liquidacion) =>{

        return(
            Array.isArray(array_sub_liquidacion) ? array_sub_liquidacion.map((sub_liquidacion, item) => (
                <>
                    <tr key={item} style={{fontSize : '12px'}} >
                        <td><span className="badge text-bg-primary">{sub_liquidacion.id_comercio}</span></td>
                        <td>{sub_liquidacion.comercio}</td>
                        <td className='center'>${formatearMoneda(sub_liquidacion.venta_bruta)}</td>

                        {sub_liquidacion.tipo_comercio_id === 3?
                            <>
                                <td className='center'>${formatearMoneda(sub_liquidacion.venta_bruta - sub_liquidacion.venta_neta_vendedor)}</td>
                                <td className='center'>${formatearMoneda(sub_liquidacion.cobros_prestamo_vendedor)}</td>
                            </>
                        :''}

                        {sub_liquidacion.tipo_comercio_id === 2?
                            <>
                                <td className='center'>${formatearMoneda(sub_liquidacion.venta_bruta - (sub_liquidacion.venta_neta_promotor + sub_liquidacion.venta_neta_vendedor))}</td>
                                <td className='center'>${formatearMoneda(sub_liquidacion.cobros_prestamo_promotor)}</td>
                            </>
                        :''}

                        {sub_liquidacion.tipo_comercio_id === 1?
                            <>
                                <td className='center'>${formatearMoneda(sub_liquidacion.venta_bruta - (sub_liquidacion.venta_neta_promotor + sub_liquidacion.venta_neta_vendedor + sub_liquidacion.venta_neta_admin_externo))}</td>
                                <td className='center'>${formatearMoneda(sub_liquidacion.cobros_prestamo_admin_externo)}</td>
                            </>
                        :''}                                           
                        
                        {/*<td className='center'>${formatearMoneda(sub_liquidacion.abonos)}</td>*/}
                        <td className='center'>${formatearMoneda(sub_liquidacion.premios)}</td>
                        {/*<td className='center'>${formatearMoneda(sub_liquidacion.ingresos)}</td>*/}
                        {/*<td className='center'>${formatearMoneda(sub_liquidacion.egresos)}</td>*/}
                        
                        {sub_liquidacion.tipo_comercio_id === 3?                                                        
                            <td className='center'>${formatearMoneda((sub_liquidacion.venta_bruta + sub_liquidacion.cobros_prestamo_vendedor) - (sub_liquidacion.venta_neta_vendedor + sub_liquidacion.premios))}</td>                                                        
                        :''}

                        {sub_liquidacion.tipo_comercio_id === 2?                                                        
                            <td className='center'>${formatearMoneda((sub_liquidacion.venta_bruta + sub_liquidacion.cobros_prestamo_promotor) - (sub_liquidacion.venta_neta_promotor + sub_liquidacion.venta_neta_vendedor + sub_liquidacion.premios))}</td>                                                        
                        :''}

                        {sub_liquidacion.tipo_comercio_id === 1?                                                        
                            <td className='center'>${formatearMoneda((sub_liquidacion.venta_bruta + sub_liquidacion.cobros_prestamo_admin_externo) - (sub_liquidacion.venta_neta_promotor + sub_liquidacion.venta_neta_vendedor + sub_liquidacion.venta_neta_admin_externo + sub_liquidacion.premios))}</td>
                        :''} 
                    </tr>
                </>
            )) : <tr></tr>
        )
    }

    const handleArmarTotalizadoSubLiquidacion = (array_sub_liquidacion) =>{

        let totalVentaBruta = 0;
        let totalVentaNeta = 0;
        let totalCobrosPrestamo = 0;
        let totalAbonos = 0;
        let totalPremios = 0;
        let totalIngresos = 0;
        let totalEgresos = 0;
        let totalNetoFinal = 0;

        if(Array.isArray(array_sub_liquidacion) ){

            array_sub_liquidacion.map((sub_liquidacion) => {

                totalVentaBruta += parseFloat(sub_liquidacion.venta_bruta);
                
                totalAbonos += parseFloat(sub_liquidacion.abonos);
                totalPremios += parseFloat(sub_liquidacion.premios);
                totalIngresos += parseFloat(sub_liquidacion.ingresos);
                totalEgresos += parseFloat(sub_liquidacion.egresos);

                if (sub_liquidacion.tipo_comercio_id === 3) {
                    totalVentaNeta += parseFloat(sub_liquidacion.venta_bruta - sub_liquidacion.venta_neta_vendedor);
                    totalNetoFinal += parseFloat((sub_liquidacion.venta_bruta + sub_liquidacion.cobros_prestamo_vendedor) - (sub_liquidacion.venta_neta_vendedor + sub_liquidacion.premios));
                    totalCobrosPrestamo += parseFloat(sub_liquidacion.cobros_prestamo_vendedor);
                } else if (sub_liquidacion.tipo_comercio_id === 2) {
                    totalVentaNeta += parseFloat(sub_liquidacion.venta_bruta - (sub_liquidacion.venta_neta_vendedor + sub_liquidacion.venta_neta_promotor));
                    totalNetoFinal += parseFloat((sub_liquidacion.venta_bruta + sub_liquidacion.cobros_prestamo_promotor) - (sub_liquidacion.venta_neta_promotor + sub_liquidacion.venta_neta_vendedor + sub_liquidacion.premios));
                    totalCobrosPrestamo += parseFloat(sub_liquidacion.cobros_prestamo_promotor);
                } else if (sub_liquidacion.tipo_comercio_id === 1) {
                    totalVentaNeta += parseFloat(sub_liquidacion.venta_bruta - (sub_liquidacion.venta_neta_vendedor + sub_liquidacion.venta_neta_promotor + sub_liquidacion.venta_neta_admin_externo));
                    totalNetoFinal += parseFloat((sub_liquidacion.venta_bruta + sub_liquidacion.cobros_prestamo_admin_externo) - (sub_liquidacion.venta_neta_promotor + sub_liquidacion.venta_neta_vendedor + sub_liquidacion.venta_neta_admin_externo + sub_liquidacion.premios));
                    totalCobrosPrestamo += parseFloat(sub_liquidacion.cobros_prestamo_admin_externo);
                }
            })
        }

        return (
            <tr className='table-warning'>
                <td className='text-end' colSpan={2}><b>Total</b></td>
                <td className='center'><b>${formatearMoneda(totalVentaBruta)}</b></td>
                <td className='center'><b>${formatearMoneda(totalVentaNeta)}</b></td>
                <td className='center'><b>${formatearMoneda(totalCobrosPrestamo)}</b></td>
                {/*<td className='center'><b>${formatearMoneda(totalAbonos)}</b></td>*/}
                <td className='center'><b>${formatearMoneda(totalPremios)}</b></td>
                {/*<td className='center'><b>${formatearMoneda(totalIngresos)}</b></td>*/}
                {/*<td className='center'><b>${formatearMoneda(totalEgresos)}</b></td>*/}
                <td className='center'><b>${formatearMoneda(totalNetoFinal)}</b></td>
            </tr>
        );
    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className='row'>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Desde:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_ini" 
                                    name="fecha_ini" 
                                    value={filtro.fecha_ini}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Hasta:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_fin" 
                                    name="fecha_fin" 
                                    value={filtro.fecha_fin}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Tipos de Usuario</label>
                            <div className='input-group'>
                                <select 
                                    className="form-select form-control"
                                    name="tipo_comercio"
                                    value={filtro.tipo_comercio}
                                    onChange={handleInputFiltroChange}
                                >
                                    <option value="">-- Seleccione --</option>
                                    { props.data_sesion.rol_id === 0?
                                    <>
                                        <option value="ADMIN-EXTERNO">SOCIO EMPRESA</option>
                                        <option value="PROMOTOR">PROMOTOR</option>
                                    </>
                                    :''}
                                    { props.data_sesion.rol_id === 1?
                                    <>
                                        <option value="PROMOTOR">PROMOTOR</option>
                                    </>
                                    :''}
                                    { props.data_sesion.rol_id === 2 ?
                                        <option value="VENDEDOR">VENDEDOR</option>
                                    :''}
                                </select>
                            </div>
                        </div>
                        { verTable ?
                        <div className="col-md-3">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                <input 
                                    type="text" 
                                    className="form-control form-control-sm" 
                                    placeholder="Buscar..." 
                                    aria-label="Buscar" 
                                    aria-describedby="basic-addon1" 
                                    value={filterTabla}
                                    onChange={(e) => setFilterTabla(e.target.value)} 
                                />
                            </div>
                        </div>
                        : ''}
                        <div className="col-md-1">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary btn-md"
                                    onClick={handleBuscarLiquidacion}
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>            
                    
                    {verTable ?
                        <div className="card" style={{ overflow : 'auto'}}>
                            <div className="card-body">
                                <div className='table-container'>
                                    <div className="table-responsive-sm">
                                        <table className='table table-hover table-light text-center' style={{fontSize : '16px'}}>
                                            <thead>
                                                <tr>
                                                    <td><b>Codigo</b></td>
                                                    <td><b>{filtro.tipo_comercio}</b></td>
                                                    <td className='text-center'><b>V. Bruta</b></td>
                                                    <td className='text-center'><b>Neto</b></td>
                                                    <td className='text-center'><b>Cobro Prestamo</b></td>
                                                    {/*<td className='text-center'><b>Abono Prestamo</b></td>*/}
                                                    <td className='text-center'><b>Premios</b></td>
                                                    {/*<td className='text-center'><b>Ingresos</b></td>*/}
                                                    {/*<td className='text-center'><b>Egresos</b></td>*/}
                                                    <td className='text-center'><b>Total</b></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {Array.isArray(listaLiquidacion) ? filterDataTabla.map((liquidacion, index) => (
                                            <>
                                                <tr key={index} style={{fontSize : '16px', cursor:'pointer' }} data-bs-toggle="collapse" data-bs-target={"#"+index+"_collapse"} aria-expanded="false" aria-controls="collapseExample" >
                                                    <td><span className="badge text-bg-primary">{liquidacion.id_comercio}</span></td>
                                                    <td>{liquidacion.comercio}</td>
                                                    <td className='center'>${formatearMoneda(liquidacion.venta_bruta)}</td>

                                                    {filtro.tipo_comercio === 'VENDEDOR'?
                                                        <>
                                                            <td className='center'>${formatearMoneda(liquidacion.venta_bruta - liquidacion.venta_neta_vendedor)}</td>
                                                            <td className='center'>${formatearMoneda(liquidacion.cobros_prestamo_vendedor)}</td>
                                                        </>
                                                    :''}

                                                    {filtro.tipo_comercio === 'PROMOTOR'?
                                                        <>
                                                            <td className='center'>${formatearMoneda(liquidacion.venta_bruta - (liquidacion.venta_neta_promotor + liquidacion.venta_neta_vendedor))}</td>
                                                            <td className='center'>${formatearMoneda(liquidacion.cobros_prestamo_promotor)}</td>
                                                        </>
                                                    :''}

                                                    {filtro.tipo_comercio === 'ADMIN-EXTERNO'?
                                                        <>
                                                            <td className='center'>${formatearMoneda(liquidacion.venta_bruta - (liquidacion.venta_neta_promotor + liquidacion.venta_neta_vendedor + liquidacion.venta_neta_admin_externo))}</td>
                                                            <td className='center'>${formatearMoneda(liquidacion.cobros_prestamo_admin_externo)}</td>
                                                        </>
                                                    :''}                                           
                                                    
                                                    {/*<td className='center'>${formatearMoneda(liquidacion.abonos)}</td>*/}
                                                    <td className='center'>${formatearMoneda(liquidacion.premios)}</td>
                                                    {/*<td className='center'>${formatearMoneda(liquidacion.ingresos)}</td>*/}
                                                    {/*<td className='center'>${formatearMoneda(liquidacion.egresos)}</td>*/}
                                                    
                                                    {filtro.tipo_comercio === 'VENDEDOR'?                                                        
                                                        <td className='center'>${formatearMoneda((liquidacion.venta_bruta + liquidacion.cobros_prestamo_vendedor ) - (liquidacion.venta_neta_vendedor + liquidacion.premios))}</td>                                                        
                                                    :''}

                                                    {filtro.tipo_comercio === 'PROMOTOR'?                                                        
                                                        <td className='center'>${formatearMoneda((liquidacion.venta_bruta  +  liquidacion.cobros_prestamo_promotor) - (liquidacion.venta_neta_promotor + liquidacion.venta_neta_vendedor + liquidacion.premios))}</td>                                                        
                                                    :''}

                                                    {filtro.tipo_comercio === 'ADMIN-EXTERNO'?                                                        
                                                        <td className='center'>${formatearMoneda((liquidacion.venta_bruta +  liquidacion.cobros_prestamo_admin_externo) - (liquidacion.venta_neta_promotor + liquidacion.venta_neta_vendedor + liquidacion.venta_neta_admin_externo + liquidacion.premios))}</td>
                                                    :''} 
                                                    
                                                </tr>
                                                <tr className='collapse' id={index+"_collapse"} >
                                                    <td  colspan="10">
                                                        <table className='table table-hover table-info' style={{fontSize : '12px'}}>
                                                            <thead>
                                                                <tr>
                                                                    <td className='text-center text-success'><b>Codigo</b></td>
                                                                    <td className='text-center text-success'><b>Comercio</b></td>
                                                                    <td className='text-center text-success'><b>V. Bruta</b></td>
                                                                    <td className='text-center text-success'><b>Neto</b></td>
                                                                    <td className='text-center text-success'><b>Cobro Prestamo</b></td>
                                                                    {/*<td className='text-center text-success'><b>Abono Prestamo</b></td>*/}
                                                                    <td className='text-center text-success'><b>Premios</b></td>
                                                                    {/*<td className='text-center text-success'><b>Ingresos</b></td>*/}
                                                                    {/*<td className='text-center text-success'><b>Egresos</b></td>*/}
                                                                    <td className='text-center text-success'><b>Total</b></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {handleArmarTablaSubLiquidacion(liquidacion.sub_liquidacion)}
                                                                {handleArmarTotalizadoSubLiquidacion(liquidacion.sub_liquidacion)}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>                                                
                                            </>    
                                            )) : <tr></tr>}
                                            <tr className='table-success'  style={{fontSize : '16px'}}>
                                                <td></td>
                                                <td className='text-end'><b>Total:</b></td>
                                                <td className='text-center'><b>${formatearMoneda(total_v_brutar)}</b></td>
                                                <td className='text-center'><b>${formatearMoneda(total_neto)}</b></td>
                                                <td className='text-center'><b>${formatearMoneda(total_cobro_prestamo)}</b></td>
                                                {/*<td className='text-center'><b>${formatearMoneda(total_abono_prestamo)}</b></td>*/}
                                                <td className='text-center'><b>${formatearMoneda(total_premios)}</b></td>
                                                {/*<td className='text-center'><b>${formatearMoneda(total_ingresos)}</b></td>*/}
                                                {/*<td className='text-center'><b>${formatearMoneda(total_egresos)}</b></td>*/}
                                                <td className='text-center'><b>${formatearMoneda(total_total)}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :''}

                    {spinerTabla ?
                    <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                    :''}
                    {verMnesaje ?
                    <div className='text-center text-secondary my-4'>
                        <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                        <p>Por favor elija una combinación de filtros diferente.</p>
                    </div>
                    :''}
                </section>
            </main>
            <DetalleVenta></DetalleVenta>
        </React.Fragment>
    )
}

const mapSateToProps = state => {
    return {
        data_sesion: state.data_sesion,
    }
}

const mapDispatchToProps  = {
    setIdTransaccion
};

export default connect(mapSateToProps, mapDispatchToProps)(Liquidacion)
import React, {useState, useEffect} from 'react';
import Swal  from 'sweetalert2';
import { connect } from 'react-redux';
import { setListaZonas } from '../../../../actions'
import { alerta, toast } from '../../../../services/utilities';
import { crearZona, getZonas} from '../../../../services/Zona';

function Nueva(props){
    
    var loterias_seleccionadas = [];
    const lista_loterias = props.tableLoterias.data;

    const [zona, setZona] = useState({
        "nombre": ""
    });

    const handleCheckiarLoteria = (event) => {

        if(event.target.checked){
            loterias_seleccionadas.push(event.target.value);
        }else{
            loterias_seleccionadas = loterias_seleccionadas.filter(item => item !== event.target.value)
        }
    }

    const handleInputZona = (event) => {
        const { name, value } = event.target;
        setZona({ ...zona, [name]: value });
    };

    const handleRefrescarListaZonas = () => {

        getZonas().then(response => {
            if(response.status === 200){
                props.setListaZonas(response.data.data)
            }else{
                alerta('No se cargo la lista de zonas o no hay zonas actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });
    }

    const handleGuardarZona = () => {
        
        if(zona.nombre == ''){
            alerta('Debe escribir el nombre de la zona');
            return;
        }else if(!Array.isArray(loterias_seleccionadas) || loterias_seleccionadas.length == 0){
            alerta('Debe seleccionar la menos una loteria');
            return;
        }

        let data = {
            "nombre":zona.nombre,
            "loterias": loterias_seleccionadas
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la información de la loteria ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                crearZona(data).then(response => {
                    if(response.status == 201){
                        handleRefrescarListaZonas();
                        setZona({"nombre":""});
                        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
                        checkboxes.forEach(checkbox => {
                            checkbox.checked = false;
                          });
                        toast('¡ Perfecto !','Zona creada exitosamente','success');
                    }else{
                        alerta('Se presento un error al intentar guardar la zona',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    return(
        <React.Fragment>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Nueva <span>| Zona</span></h5>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="nombre" name="nombre" value={zona.nombre} onChange={handleInputZona} />
                                    <label htmlFor="floatingName">Nombre Zona: <span className="text-danger">*</span></label>
                                </div>
                            </div>
                            
                            <div className="col-md-12">
                                <table className="table table-hover table-sm" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>Loterias</th>
                                            <th><i className="bi bi-check-square"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {Array.isArray(lista_loterias) ? lista_loterias.map((loteria, index) => (
                                        <tr key={index}>
                                            <td>{loteria.prefijo}</td>
                                            <td>
                                                <div className="form-check text-center">
                                                    <input className="form-check-input" type="checkbox" value={loteria.id_loteria} onChange={handleCheckiarLoteria} id="flexCheckDefault" />
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <tr></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-12">
                                <button type="button" className="btn btn-primary" onClick={handleGuardarZona}>
                                    Crear Nueva Zona
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setListaZonas
};

const mapSateToProps = state => {

    return {
        tableLoterias: state.tableLoterias
    }
}

export default  connect(mapSateToProps, mapDispatchToProps)(Nueva);
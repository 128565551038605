import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setListaEncabezadoResultado } from '../../../../actions'
import { getEncabezadoResultado, editarResultado } from '../../../../services/Resultados';
import { alerta, toast, cerrarModal } from '../../../../services/utilities';
import { getEncabezados } from '../../../../services/Resultados';

import Swal  from 'sweetalert2';

function Editar(props){

    useEffect(() => {
        if(props.id_encabezado_resultado != 0){
            getEncabezadoResultado(props.id_encabezado_resultado).then(response => {
                if(response.status === 200){
   
                    if(parseInt(props.rol_id) !== 0){
                        setEstadoGlobal(2);
                    }else{
                        setEstadoGlobal(response.data.data.estado_id)
                    }

                    setResultado(response.data.data);

                }else{
                    alerta('No fue posible cargar la informacion del resultado',response.response.data.status_message??response.message,'warning');
                }
            }); 
        }
   },[props.id_encabezado_resultado]);
    
    const [estado_global, setEstadoGlobal] = useState();
    const [resultado, setResultado] = useState({
        "id_encabezado_resultado": 0,
        "fecha": "",
        "observacion": "",
        "estado_id": "",
        "nombre": "",
        "resultado_loteria": []
    });

    const handleInputResultadoChange = (event, index = false) => {

        const { name, value } = event.target;

        if(index === false){
            setResultado({ ...resultado, [name]: value });
        }else{

            const updatedItemsResultado = [...resultado.resultado_loteria];

            let temp = {
                "id_resultado_loteria": resultado.resultado_loteria[index].id_resultado_loteria,
                "prefijo": resultado.resultado_loteria[index].prefijo,
                "id_loteria": resultado.resultado_loteria[index].loteria_id,
                "numero": name == `numero_${index}` ? value : resultado.resultado_loteria[index].numero,
                "serie": name == `serie_${index}` ? value : resultado.resultado_loteria[index].serie,
                "signo": name == `astro_${index}` ? value : resultado.resultado_loteria[index].signo
            }

            updatedItemsResultado[index] = temp;
            
            setResultado({
                "id_encabezado_resultado": resultado.id_encabezado_resultado,
                "fecha": resultado.fecha,
                "observacion": resultado.observacion,
                "estado_id": resultado.estado_id,
                "nombre": resultado.nombre,
                "resultado_loteria": updatedItemsResultado
            });

        }
    };

    const handleValidarCampos = () => {

        let msg = false;

        resultado.resultado_loteria.forEach((resultado, index) => {

            if (resultado.numero === null || resultado.numero === '') 
                msg =`El campo "Numero" de la loteria "${resultado.prefijo}" no puede estar vacio`;
            
            if(resultado.numero !== null && resultado.numero.length > 4)
                msg =`El valor del campo "Numero" de la loteria "${resultado.prefijo}" no puede ser mayor a 4 digitos`;

            if(resultado.serie !== null &&  resultado.serie.length > 3)
                msg =`El valor del campo "Serie" de la loteria "${resultado.prefijo}" no puede ser mayor a 3 digitos`;

        });
        
        msg = false;
        return msg;
    }

    const handleRefrescarTablaResultados = () =>{

        getEncabezados().then(response => {
            if(response.status === 200){
                props.setListaEncabezadoResultado(response.data.data)
            }else{
                alerta('No se cargo la tabla de resultados o no hay resultados actualemente creados',response.response.data.status_message??response.message,'warning');
            }
        });
    }

    const handleGuardarResultado = () =>{

        if(handleValidarCampos()){
            alerta(handleValidarCampos());
            return;
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la información del resultado ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                editarResultado(resultado).then(response => {
                    if(response.status == 201){
                        handleRefrescarTablaResultados()
                        cerrarModal('modalEditarResultado');
                        toast('¡ Perfecto !','Resultado guardado exitosamente','success');
                    }else{
                        alerta('Se presento un error al intentar guardar el resultado',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalEditarResultado" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalEditarResultado" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Ver Resultado</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            id="fecha" 
                                            name="fecha" 
                                            disabled={true}
                                            value={resultado.fecha}
                                        />
                                        <label htmlFor="floatingName">Fecha:</label>
                                    </div>
                                </div>
                                <div className="col-md-6 d-none">
                                    <div className="form-floating mb-3">
                                        <select
                                            className="form-select" 
                                            id="estado_id" 
                                            name="estado_id" 
                                            aria-label="State"
                                            disabled={estado_global == 2 ? true:false}
                                            value={resultado.estado_id}
                                            onChange={handleInputResultadoChange}                                            
                                        >
                                            <option value="5">Pendiente</option>
                                            <option value="2">Aprobado</option>
                                        </select>
                                        <label htmlFor="estado_id">Estado:</label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="observacion" 
                                            name="observacion"
                                            disabled={estado_global == 2 ? true:false}
                                            value={resultado.observacion} 
                                            onChange={handleInputResultadoChange}                                            
                                            />
                                        <label htmlFor="floatingName">Observación:</label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <table className="table table-hover table-sm">
                                        <thead>
                                            <tr>
                                                <th className="text-left">Loteria</th>
                                                <th className="text-left" style={{width:'20%'}}>Numero</th>
                                                <th className="text-left" style={{width:'20%'}}>Serie</th>
                                                <th  className="text-left">Astro</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Array.isArray(resultado.resultado_loteria) ?resultado.resultado_loteria.map((r, index) => (
                                            <tr key={index}>
                                                <td>{r.prefijo}</td>
                                                    <td>
                                                    <div className="input-group input-group-sm">
                                                        <input 
                                                            type="number" 
                                                            className="form-control" 
                                                            name={`numero_${index}`}
                                                            disabled={estado_global == 2 ? true:false}
                                                            value={resultado.resultado_loteria[index].numero??''}
                                                            onChange={(e) => handleInputResultadoChange(e, index)}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-group input-group-sm">
                                                        <input 
                                                            type="number" 
                                                            className="form-control" 
                                                            name={`serie_${index}`}
                                                            disabled={estado_global == 2 ? true:false}
                                                            value={resultado.resultado_loteria[index].serie??''}
                                                            onChange={(e) => handleInputResultadoChange(e, index)}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-group input-group-sm">
                                                        <select
                                                            className="form-select" 
                                                            id="signo" 
                                                            name={`astro_${index}`}
                                                            aria-label="State"
                                                            disabled={estado_global == 2 ? true:false}
                                                            value={resultado.resultado_loteria[index].signo??''}
                                                            onChange={(e) => handleInputResultadoChange(e, index)}
                                                        >
                                                            <option value="">n/a</option>
                                                            <option value="Aries">Aries</option>
                                                            <option value="Tauro">Tauro</option>
                                                            <option value="Geminis">Geminis</option>
                                                            <option value="Cancer">Cáncer</option>
                                                            <option value="Leo">Leo</option>
                                                            <option value="Virgo">Virgo</option>
                                                            <option value="Libra">Libra</option>
                                                            <option value="Escorpion">Escorpion</option>
                                                            <option value="Sagitario">Sagitario</option>
                                                            <option value="Capricornio">Capricornio</option>
                                                            <option value="Acuario">Acuario</option>
                                                            <option value="Piscis">Piscis</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) : <tr></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {estado_global == 2 ? '':
                                <button type="button" className="btn btn-primary"  onClick={handleGuardarResultado}>Guardar</button>
                            }
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

const mapSateToProps = state => {

    return {
        id_encabezado_resultado: state.id_encabezado_resultado
    }
}

const mapDispatchToProps  = {
    setListaEncabezadoResultado
};
export default connect(mapSateToProps, mapDispatchToProps)(Editar)
import React from "react";
import { connect } from 'react-redux';
import { setIdEncabezadoResultado } from '../../../../actions'
import { getEncabezado } from '../../../../services/Resultados';

function Tabla (props){

    const listaEncabezadoResultado = props.listaEncabezadoResultado;

    const handleResultadoSeleccionado = (id_encabezado_resultado) =>{
        props.setIdEncabezadoResultado(id_encabezado_resultado);
    }

    return(

        <React.Fragment>
            <div className="table-responsive">
                <table className="table table-hover table-sm table-center">
                    <thead>
                        <tr className="text-center">
                            <th>Codigo</th>
                            <th>Fecha Resultado</th>
                            <th>Responsable</th>
                            <th>Observación</th>
                            <th>Estado</th>
                            <th>Ver</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Array.isArray(listaEncabezadoResultado) ? listaEncabezadoResultado.map((encabezado, index) => (
                        <tr className="text-center" key={index}>
                            <td><b>{encabezado.id_encabezado_resultado}</b></td>
                            <td>{encabezado.fecha}</td>
                            <td>{encabezado.nombre}</td>
                            <td>{encabezado.observacion}</td>
                            <td className="text-center">
                                {encabezado.estado_id == '2' ?<p className='text-success'>Aprobado</p> : <p className='text-danger'>Pendiente</p>}
                            </td>
                            <td>
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary btn-sm " 
                                    title="Ver Loteria" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#modalEditarResultado"
                                    onClick={() => handleResultadoSeleccionado(encabezado.id_encabezado_resultado)}
                                    >
                                    <i className="bi bi-gear"></i>
                                </button>
                            </td>
                        </tr>
                    )) : <tr></tr>}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )

}

const mapSateToProps = state => {

    return {
        listaEncabezadoResultado: state.listaEncabezadoResultado
    }
}

const mapDispatchToProps  = {
    setIdEncabezadoResultado
};

export default  connect(mapSateToProps, mapDispatchToProps)(Tabla);
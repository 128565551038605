import React from "react";
import { Link } from "react-router-dom";

function Inicio(props){
    
    return(
        <React.Fragment>
            {props.rol_id === -1?
                <li className="nav-item">
                    <Link to="/root/dashboard" className="nav-link " href="#">
                        <i className="bi bi-house"></i>
                        <span>Inicio</span>
                    </Link>
                </li>
            :
                <li className="nav-item">
                    <Link to="/dashboard" className="nav-link " href="#">
                        <i className="bi bi-house"></i>
                        <span>Inicio</span>
                    </Link>
                </li>
            }
        </React.Fragment>
    );
}

export default Inicio;
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setTablaLoteria, setTablaBloqueos } from '../../../../actions'
import { getLoterias } from '../../../../services/Loterias';
import { getBloqueos } from '../../../../services/Bloqueo';
import { alerta } from '../../../../services/utilities';
import { getModalidadJuegoEmpresa } from '../../../../services/Usuario';
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import HeaderModulo from "../../../header/HeaderModulo";
import Nuevo from "./Nuevo";
import Tabla from "./Tabla";
import Editar from './Editar';

function Bloqueos(props){

    useEffect(() => {

        getLoterias().then(response => {
            if(response.status === 200){
                props.setTablaLoteria(response.data)
            }else{
                alerta('No se cargo la tabla de loterias o no hay loterias actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });

        getBloqueos().then(response => {
            if(response.status === 200){
                props.setTablaBloqueos(response.data)
            }else{
                alerta('No se cargo la tabla de bloqueos o no hay bloqueos actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });

        getModalidadJuegoEmpresa().then(response => {

            if(response.status === 200){

                const data = response.data.data;
                
                data.forEach(categoria => {
                    if(categoria.id_categoria_modalidad_juego === 1){
                        setTieneChance(categoria.id_categoria_modalidad_juego);
                    }else if(categoria.id_categoria_modalidad_juego === 2){
                        setTieneQuinta(categoria.id_categoria_modalidad_juego);
                    }else if(categoria.id_categoria_modalidad_juego === 3){
                        setTieneAstro(categoria.id_categoria_modalidad_juego);
                    }
                });

            }else{
                alerta('No se cargo las modalidades de juego',response.response.data.status_message??response.message,'warning');
            }
        });
    
    }, []);

    const [tieneChance, setTieneChance] = useState(false);
    const [tieneQuinta, setTieneQuinta] = useState(false);
    const [tieneAstro, setTieneAstro] = useState(false);

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <Nuevo tieneChance={tieneChance} tieneQuinta={tieneQuinta} tieneAstro={tieneAstro}></Nuevo>
                    <Tabla></Tabla>
                    <Editar tieneChance={tieneChance} tieneQuinta={tieneQuinta} tieneAstro={tieneAstro}></Editar>
                </section>
            </main>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setTablaLoteria,
    setTablaBloqueos
};


export default connect(null, mapDispatchToProps)(Bloqueos)
import InstaceAxios  from "./Api";

export const editarResultado = async (data) => {
    const result = await InstaceAxios.post('resultado/editar', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const guardarResultado = async (data) => {
    const result = await InstaceAxios.post('resultado/crear', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getEncabezados = async () => {
    const result = await InstaceAxios.get(`resultado/encabezados`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getEncabezadoResultado = async (id_encabezado_resultado = false) => {
    const result = await InstaceAxios.get(`resultado/encabezado?id_encabezado_resultado=${id_encabezado_resultado}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getPremiosEmpesa = async (fecha_ini, fecha_fin,) => {
    const result = await InstaceAxios.get(`resultado/premios?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const actulizarAutomatico = async (estado) => {
    const result = await InstaceAxios.get(`resultado/actulizar/estado?estado=${estado}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getPremiosBono = async (fecha_ini, fecha_fin, id_empresa = null) => {
    const result = await InstaceAxios.get(`resultado/premios/bono?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}&id_empresa=${id_empresa}`)
        .then(res => res)
        .catch(error => error)
    return result
}






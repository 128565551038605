import React from "react";
import Item from "./item_menu";

function BonoMillonario(props){

    return(
        <React.Fragment>
            <li className="nav-item">
                <a className="nav-link collapsed" data-bs-target="#bono-nav" data-bs-toggle="collapse" href="#">
                    <i className="bi bi-dice-3"></i>
                    <span>Bono Millonario</span>
                    <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="bono-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <Item nombre="Liquidacion" ruta="/bono/liquidacion" ></Item>
                    <Item nombre="Premios" ruta="/bono/premios"></Item>
                </ul>
            </li>
        </React.Fragment>
    );
}

export default BonoMillonario;
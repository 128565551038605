import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { setGestionando, setIdComercioGestionado } from '../../actions'
import Inicio from './Inicio';
import Administracion from "./Administracion";
import Operaciones from "./Operaciones";
import Cartera from "./Cartera";
import { validateToken, generarTokenGestion } from "../../services/Login";
import { alerta } from '../../services/utilities';
import Swal  from 'sweetalert2';
import Reportes from "./Reportes";
import MenuRoot from "./root/MenuRoot";
import BonoMillonario from "./BonoMillonario";

function Aside(props){

    const token = localStorage.getItem("token")
    const gestion = localStorage.getItem("gestion")
    const id_comercio_gestionado = localStorage.getItem("id_comercio_gestionado")
    const token_gestionador = localStorage.getItem("token_gestionador")

    useEffect(() => {

        props.setGestionando(gestion);

        if(props.gestionando === 'NO' ){
            validateToken(token).then(response => {
                if(response.status !== 200){
                    window.location.href = '/unauthorized';
                }else{
                    localStorage.setItem("token", response.data.jwt)
                }
            });
        }else{

            if(parseInt(props.id_comercio_gestionado) !==0){


                Swal.fire({
                    title: "¿ Esta seguro de activar la gestion ?",
                    text: "Si esta seguro presione el boton Activar de lo contrario Cancelar",
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, Activar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        generarTokenGestion(props.id_comercio_gestionado).then(response => {
                            if(response.status !== 200){
                                alerta('Se presento un error al intentar gestionar el comercio',response.response.data.status_message??response.message);
                            }else{
                                props.setGestionando('SI')
                                localStorage.setItem("token_gestionador",token);
                                localStorage.setItem("token", response.data.jwt)
                                localStorage.setItem("gestion",'SI');
                                localStorage.setItem("id_comercio_gestionado", props.id_comercio_gestionado );
                                window.location.href = '/dashboard';
                            }
                        });
                    }
                });

                
            }
        }
    }, [props.id_comercio_gestionado]);

    const handleCerrarGestion = () => {

        Swal.fire({
            title: "¿ Esta seguro de salir del gestion ?",
            text: "Si esta seguro presione el boton Salir de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Salir'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.setItem("gestion",'NO');
                localStorage.setItem("token", localStorage.getItem("token_gestionador"));
                localStorage.removeItem('token_gestionador');
                setIdComercioGestionado(0);
                if(props.rol_id === -1){
                    window.location.href = '/root/dashboard';
                }else{
                    window.location.href = '/admnistracion/usuarios';
                }
            }
        });
    }
    let tiene_bono = false;

    if(Array.isArray(props.data_usuario.modalidades)){
        tiene_bono = props.data_usuario.modalidades.some(function(elemento) {
            return elemento.id_categoria_modalidad_juego === 4;
        });
    }

    return(
        <React.Fragment>
            <aside id="sidebar" className="sidebar">

                {props.data_sesion.rol_id >= 0?
                    <ul className="sidebar-nav" id="sidebar-nav">
                        <Inicio rol_id={props.data_sesion.rol_id}></Inicio>
                        <Administracion rol_id={props.data_sesion.rol_id} modalidades={props.data_usuario.modalidades} ></Administracion>
                        <Operaciones rol_id={props.data_sesion.rol_id}></Operaciones>
                        <Cartera rol_id={props.data_sesion.rol_id}></Cartera>
                        <Reportes rol_id={props.data_sesion.rol_id} data_usuario={props.data_usuario.data} ></Reportes>
                        {tiene_bono ? <BonoMillonario data_usuario={props.data_usuario.data} />:''}
                    </ul>
                :  props.data_sesion.rol_id === -1 ?
                    <ul className="sidebar-nav" id="sidebar-nav">
                        <Inicio rol_id={props.data_sesion.rol_id}></Inicio>
                        <MenuRoot></MenuRoot>
                    </ul> : 
                    <p className="card-text placeholder-glow">
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-12"></span>
                    </p>
                }

                    

                { props.gestionando === 'SI' ?
                    <div className="alert alert-danger mx-2 my-2 p-2 text-center" role="alert" style={{ position: 'absolute', bottom: '10px', width: '80%' }}>
                        <i className="bi bi-people px-1 fs-5"></i>
                        <span style={{fontSize: '18px'}}><b>Gestionando</b></span>
                        <button 
                            type="button" 
                            className="btn-close" 
                            aria-label="Close" 
                            style={{ position: 'absolute', top: '0px', right: '0px' }}
                            onClick={(e) => handleCerrarGestion(e)}
                        >
                        </button>
                    </div>
                    : ''
                }
            </aside>
        </React.Fragment>
    );

}

const mapDispatchToProps  = {
    setGestionando,
    setIdComercioGestionado
}

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
        data_usuario: state.data_usuario,
        gestionando: state.gestionando,
        id_comercio_gestionado: state.id_comercio_gestionado
    }
}

export default  connect(mapSateToProps, mapDispatchToProps)(Aside);
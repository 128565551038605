import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setLoteria } from '../../../../actions'
import { getLoteria } from '../../../../services/Loterias';
import { alerta } from '../../../../services/utilities';

function Tabla(props){

    const lista_loterias = props.tableLoterias.data;
    const estilosDias = {
        LUNES: 'badge rounded-pill text-bg-dark m-1',
        MARTES: 'badge rounded-pill text-bg-info m-1',
        MIERCOLES: 'badge rounded-pill text-bg-warning m-1',
        JUEVES: 'badge rounded-pill text-bg-danger m-1',
        VIERNES: 'badge rounded-pill text-bg-success m-1',
        SABADO: 'badge rounded-pill text-bg-secondary m-1',
        DOMINGO: 'badge rounded-pill text-bg-primary m-1',
      };

    const handleLoteriaSeleccionada = (id_loteria) => {

        getLoteria(id_loteria).then(response => {
            if(response.status === 200){
                props.setLoteria(response.data.data[0]);
            }else{
                alerta('Error al cargar la loteria, intente nuevamente');
            }
        })
    }
    
    return(
        <React.Fragment>
            <table className="table table-hover table-sm">
                <thead>
                    <tr>
                    <th className="text-left">Id</th>
                        <th className="text-left">Loteria</th>
                        <th className="text-left">Dias</th>
                        <th className="text-center">Estado</th>
                        <th  className="text-center">Ver</th>
                    </tr>
                </thead>
                <tbody>
                {Array.isArray(lista_loterias) ? lista_loterias.map((loteria, index) => (
                    <tr key={index}>
                        <td className="text-left">{loteria.id_loteria}</td>
                        <td className="text-left">{loteria.prefijo}</td>
                        <td className="text-left">
                        {loteria.horario.map((horario, index) => (
                            <span className={estilosDias[horario.nombre_dia]} key={index}>
                                {horario.nombre_dia.toLowerCase()}
                            </span>
                        ))}
                        </td>
                        <td className="text-center">
                            {loteria.estado_id == '1' ?<p className='text-success'>activa</p> : <p className='text-danger'>inactiva</p>}
                        </td>
                        <td className="text-center">
                            <button 
                                type="button" 
                                className="btn btn-outline-primary btn-sm " 
                                title="Ver Loteria" 
                                data-bs-toggle="modal" 
                                data-bs-target="#modalEditarLoteria"
                                onClick={() => handleLoteriaSeleccionada(loteria.id_loteria)}
                                >
                                <i className="bi bi-gear"></i>
                            </button>
                        </td>
                    </tr>
                )) : <tr></tr>}
                </tbody>
            </table>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        tableLoterias: state.tableLoterias
    }
}

const mapDispatchToProps  = {
    setLoteria
};

export default  connect(mapSateToProps, mapDispatchToProps)(Tabla);
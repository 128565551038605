import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import { setIdComercio } from '../../../../actions'
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import { alerta, toast, formatDate, formatearMoneda } from '../../../../services/utilities';
import { getConfiguracion, guardarCuatroCifras } from '../../../../services/bono';
import Liquidacion from "./Liquidacion";
import Premios from "./Premios";

function Bono(props){

    useEffect(() => {
        
        getConfiguracion(4).then(response => {
            if(response.status === 200){                
                setCuatroCifras({
                    "id_configuracion_juego_especial": response.data.configuracion.id_configuracion_juego_especial,
                    "valor_venta": response.data.configuracion.valor_venta.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    "valor_premio_mayor": response.data.configuracion.valor_premio_mayor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    "valor_premio_menor": response.data.configuracion.valor_premio_menor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    "estado_id": response.data.configuracion.estado_id
                });

            }else{
                alerta('No fue posible cargar la informacion del bono 4 cifras',response.response.data.status_message??response.message,'warning');
            }
        });

        getConfiguracion(3).then(response => {
            if(response.status === 200){                
                setTresCifras({
                    "id_configuracion_juego_especial": response.data.configuracion.id_configuracion_juego_especial,
                    "valor_venta": response.data.configuracion.valor_venta.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    "valor_premio_mayor": response.data.configuracion.valor_premio_mayor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    "valor_premio_menor": response.data.configuracion.valor_premio_menor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    "estado_id": response.data.configuracion.estado_id
                });
            }else{
                alerta('No fue posible cargar la informacion del bono 4 cifras',response.response.data.status_message??response.message,'warning');
            }
        });
      
    },[]);

    const [cuatroCifras, setCuatroCifras] = useState({
                    "id_configuracion_juego_especial": "",
                    "valor_venta": "",
                    "valor_premio_mayor": "",
                    "valor_premio_menor": "",
                    "estado_id": ""
                });
    const [tresCifras, setTresCifras] = useState({
                    "id_configuracion_juego_especial": "",
                    "valor_venta": "",
                    "valor_premio_mayor": "",
                    "valor_premio_menor": "",
                    "estado_id": ""
                });
    const [spiner, setSpiner] = useState(false);

    const handleInputCuatroCifrasChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value.replace(/\D/g, '');
        const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        setCuatroCifras({ ...cuatroCifras, [name]: formattedNumber });
    };

    const handleInputTresCifrasChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value.replace(/\D/g, '');
        const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        setTresCifras({ ...tresCifras, [name]: formattedNumber });
    };

    const handleGuardarConfigBono = async (cifras) => {
        
        setSpiner(true);

        if(cifras === 4){
            var data = cuatroCifras;
        }else{
            var data = tresCifras;
        }   

        try {

            await guardarCuatroCifras(data).then( response => {

                if(response.status !== undefined && response.status === 200){
                    
                    if(response.data.status_message === 'OK'){
                        toast('¡ Perfecto !','Se guardaron los cambios','success');
                    }else{
                        toast(response.data.status_message);
                    }
                }else{
                    toast(response.data.status_message);
                }
            });

         }catch (error) {
            alerta('Error no controlado: '+ error);
        }finally {
            setSpiner(false);
        }

    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <section className="section">
                    
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="cifras-tab-1" data-bs-toggle="tab" data-bs-target="#4-cifras-tab" type="button" role="tab" aria-controls="4-cifras-tab" aria-selected="true">Bono Millonario Super</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="cifras-tab-2" data-bs-toggle="tab" data-bs-target="#3-cifras-tab" type="button" role="tab" aria-controls="cifras-tab-aria" aria-selected="false">Bono Millonario Pleno</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="contact-tab-3" data-bs-toggle="tab" data-bs-target="#liquidacion-tab" type="button" role="tab" aria-controls="liquidacion-tab-aria" aria-selected="false">Liquidacion</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="contact-tab-4" data-bs-toggle="tab" data-bs-target="#premios-tab" type="button" role="tab" aria-controls="premios-tab-aria" aria-selected="false">Premios</button>
                        </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="4-cifras-tab" role="tabpanel" aria-labelledby="cifras-tab">
                            <div className="row">
                                <div className="col-md-6 offset-md-3 text-center mt-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Configuración de bono</h5>
                                            <h6 className="card-subtitle mb-2 text-body-secondary">4 Cifras</h6>
                                            <p className="card-text">Todos los campos del formulario son requeridos. Recuerde que los cambios que haga se verán reflejados en todas las empresas.</p>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            name="valor_premio_mayor"
                                                            id="valor_premio_mayor"
                                                            onChange={handleInputCuatroCifrasChange}
                                                            value={cuatroCifras.valor_premio_mayor}
                                                        />
                                                        <label htmlFor="valor_premio_mayor">Valor Premio Mayor: <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            name="valor_premio_menor"
                                                            id="valor_premio_menor" 
                                                            onChange={handleInputCuatroCifrasChange}
                                                            value={cuatroCifras.valor_premio_menor}
                                                        />
                                                        <label htmlFor="valor_premio_menor">Valor Premio Menor: <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            name="valor_venta"
                                                            id="valor_venta"
                                                            onChange={handleInputCuatroCifrasChange}
                                                            value={cuatroCifras.valor_venta}
                                                        />
                                                        <label htmlFor="valor_venta">Valor Venta: <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <select 
                                                            className="form-select" 
                                                            id="estado_id" 
                                                            name="estado_id"
                                                            aria-label="State"
                                                            onChange={handleInputCuatroCifrasChange}
                                                            value={cuatroCifras.estado_id}
                                                        >
                                                            <option value="1">Activo</option>
                                                            <option value="3">Inactivo</option>
                                                        </select>
                                                        <label htmlFor="estado_id">Estado:</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn btn-success card-link" onClick={() => handleGuardarConfigBono(4)} disabled={spiner}>
                                                {spiner ? <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>:''}
                                                <span>Guardar Cambios</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="3-cifras-tab" role="tabpanel" aria-labelledby="cifras-tab-aria">
                            <div className="row">
                                <div className="col-md-6 offset-md-3 text-center mt-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Configuración de bono</h5>
                                            <h6 className="card-subtitle mb-2 text-body-secondary">3 Cifras</h6>
                                            <p className="card-text">Todos los campos del formulario son requeridos. Recuerde que los cambios que haga se verán reflejados en todas las empresas.</p>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            name="valor_premio_mayor"
                                                            id="valor_premio_mayor"
                                                            onChange={handleInputTresCifrasChange}
                                                            value={tresCifras.valor_premio_mayor} 
                                                        />
                                                        <label htmlFor="valor_premio_mayor">Valor Premio Mayor: <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            name="valor_premio_menor"
                                                            id="valor_premio_menor"
                                                            onChange={handleInputTresCifrasChange}
                                                            value={tresCifras.valor_premio_menor}
                                                        />
                                                        <label htmlFor="valor_premio_menor">Valor Premio Menor: <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            name="valor_venta"
                                                            id="valor_venta"
                                                            onChange={handleInputTresCifrasChange}
                                                            value={tresCifras.valor_venta}
                                                        />
                                                        <label htmlFor="valor_venta">Valor Venta: <span className="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <select 
                                                            className="form-select" 
                                                            id="estado_id" 
                                                            name="estado_id" 
                                                            aria-label="State"
                                                            onChange={handleInputTresCifrasChange}
                                                            value={tresCifras.estado_id}
                                                        >
                                                            <option value="1">Activo</option>
                                                            <option value="3">Inactivo</option>
                                                        </select>
                                                        <label htmlFor="estado_id">Estado:</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn btn-success card-link" onClick={() => handleGuardarConfigBono(3)} disabled={spiner}>
                                                {spiner ? <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>:''}
                                                <span>Guardar Cambios</span>
                                            </button>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="liquidacion-tab" role="tabpanel" aria-labelledby="liquidacion-tab-aria">
                            <Liquidacion/>
                        </div>
                        <div className="tab-pane fade" id="premios-tab" role="tabpanel" aria-labelledby="premios-tab-aria">
                            <Premios/>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setIdComercio
};

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
    }
}

export default connect(mapSateToProps,mapDispatchToProps)(Bono);
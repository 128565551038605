import React, {useState} from "react";
import { connect } from "react-redux";
import Item from "./item_menu";

function Reportes(props){

    let tiene_prestamos = false;

    if(Object.keys(props.data_usuario).length !== 0){
        tiene_prestamos = props.data_usuario.data.realiza_prestamos;
    }

    return(
        <React.Fragment>
            <li className="nav-item">
                <a className="nav-link collapsed" data-bs-target="#reportes-nav" data-bs-toggle="collapse" href="#">
                    <i className="bi bi-clipboard2-data"></i>
                    <span>Reportes</span>
                    <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="reportes-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <Item nombre="Venta detallada" ruta="/reporte/ventaDetallada"></Item> 
                    {props.rol_id === 0 ? <Item nombre="Venta por loteria" ruta="/reporte/ventaLoteria"></Item> :''}
                    <Item nombre="Liquidación" ruta="/reporte/liquidacion"></Item>
                    {props.data_sesion.venta_cubierta === 1 && props.rol_id === 0 ?<Item nombre="Cubierta" ruta="/reporte/cubierta"></Item> :''}
                    <Item nombre="Ingreso / Egresos" ruta="/reporte/ingresosEgresos"></Item>
                    { tiene_prestamos ? <Item nombre="Prestamos Realizados" ruta="/reporte/prestamos"></Item> :''}
                    { tiene_prestamos ? <Item nombre="Abonos" ruta="/reporte/abonos"></Item> :''}
                    { props.rol_id !== 0 && tiene_prestamos ? <Item nombre="Prestamos Solicitados" ruta="/reporte/prestamosSolicitados"></Item> :''}
                    <Item nombre="Cargas Prepago" ruta="/reporte/prepago"></Item>
                </ul>
            </li>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        data_usuario: state.data_usuario,
        data_sesion: state.data_sesion
    }
}

export default connect(mapSateToProps, null)(Reportes);
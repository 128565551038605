import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import { setIdComercio } from '../../../../actions'
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import Lista from "./lista";
import Formulario from "./formulario";

function Empresas(props){

    useEffect(() => {
      
    },[]);

    const [spiner, setSpiner] = useState(false);

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
                <button
                    style={{position: 'fixed',bottom: '20px',right: '20px', 'zIndex': '1000'}}
                    className="btn btn-success me-2"
                    data-bs-toggle="modal" 
                    data-bs-target="#modalEmpresa"
                    onClick={() => props.setIdComercio(0)}          
                ><i className="bi bi-building me-2"></i>Crear Nueva Empresa
                </button>
            <main id="main" className="main">
                <section className="section">
                    <Lista></Lista>
                    <Formulario></Formulario>   
                </section>
            </main>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setIdComercio
};

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
    }
}

export default connect(mapSateToProps,mapDispatchToProps)(Empresas);
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Header from "../../header/Header";
import HeaderModulo from "../../header/HeaderModulo";
import Aside from "../../aside/Aside";
import { alerta, toast, formatDate } from '../../../services/utilities';
import { get_mis_abonos, get_abonos_realizados } from '../../../services/Reportes';
import DetalleVenta from './DetalleVenta';

function Abonos(props){

    useEffect(() => {

    }, []);

    const [spinerTabla1, setSpinerTabla1] = useState(false);
    const [verMensaje1, setVerMensaje1] = useState(true);
    const [verTable1, setVerTable1] = useState(false);

    const [spinerTabla2, setSpinerTabla2] = useState(false);
    const [verMensaje2, setVerMensaje2] = useState(true);
    const [verTable2, setVerTable2] = useState(false);

    const [listaMisAbonos, setMisAbonos] = useState([]);
    const [listaAbonosRegistrados, setAbonosRegistrados] = useState([]);

    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date()),
        "fecha_ini_2": formatDate(new Date()),
        "fecha_fin_2": formatDate(new Date())
    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };


    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }

    const handleBuscarMisAbonos = async () => {

        setVerTable1(false);
        setVerMensaje1(false);
        setSpinerTabla1(true);

        try {
            await get_mis_abonos(filtro.fecha_ini, filtro.fecha_fin).then( response => {

                if(response.status !== undefined && response.status === 200){  

                    setMisAbonos(response.data.abonos);
                    
                    if(response.data.abonos.length > 0){
                        setVerTable1(true);
                    }else{
                        setVerMensaje1(true)
                        setVerTable1(false);
                    }
                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de prestamos  (reportes/abonos/handleBuscarPrestamos) ->'+ error);
        }finally {
            setSpinerTabla1(false);
            
        }
    }

    const handleBuscarAbonosRealizados = async () => {

        setVerTable2(false);
        setVerMensaje2(false);
        setSpinerTabla2(true);

        try {
            await get_abonos_realizados(filtro.fecha_ini_2, filtro.fecha_fin_2).then( response => {

                if(response.status !== undefined && response.status === 200){  

                    setAbonosRegistrados(response.data.abonos);
                    
                    if(response.data.abonos.length > 0){
                        setVerTable2(true);
                    }else{
                        setVerMensaje2(true)
                        setVerTable2(false);
                    }
                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de prestamos  (reportes/abonos/handleBuscarAbonosRealizados) ->'+ error);
        }finally {
            setSpinerTabla2(false);
            
        }
    }
    
    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <section className="section">
                    <div className='row'>
                        <div className={ props.data_sesion.rol_id !== 0 ? 'col-md-6' : 'col-md-6 d-none' }>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className='card-title'>Mis Abonos</h5>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label className="mb-1">Desde:</label>
                                            <div className="input-group">
                                                <input 
                                                    type="date" 
                                                    className="form-control" 
                                                    id="fecha_ini" 
                                                    name="fecha_ini" 
                                                    value={filtro.fecha_ini}
                                                    onChange={handleInputFiltroChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label className="mb-1">Hasta:</label>
                                            <div className="input-group">
                                                <input 
                                                    type="date" 
                                                    className="form-control" 
                                                    id="fecha_fin" 
                                                    name="fecha_fin" 
                                                    value={filtro.fecha_fin}
                                                    onChange={handleInputFiltroChange} 
                                                />
                                            </div>
                                        </div>
                                
                                        <div className="col-md-2">
                                            <label className="mb-1">&emsp;</label>
                                            <div className="input-group">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-primary btn-md"
                                                    onClick={handleBuscarMisAbonos}
                                                >
                                                    Buscar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {verTable1 ?
                                        <div className='table-container' style={{ overflow : 'auto', height:"400px"}}>
                                            <div className="table-responsive-sm">
                                                <table className='table table-hover'>
                                                    <thead>
                                                    <tr className='text-center'>
                                                        <td><b>Fecha</b></td>
                                                        <td><b>Hora</b></td>
                                                        <td><b>Valor</b></td>
                                                        <td><b>Observación</b></td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>                                            
                                                    {Array.isArray(listaMisAbonos) ? listaMisAbonos.map((abono, index) => (
                                                        <tr className='text-center' key={index}>
                                                            <td><span className="badge text-bg-dark">{abono.fecha}</span></td>
                                                            <td><span className="badge text-bg-dark">{abono.hora}</span></td>
                                                            <td><span className="badge text-bg-success">$ {formatearMoneda(abono.valor)}</span></td>
                                                            <td style={{fontSize : '12px'}}>{abono.descripcion}</td>
                                                        </tr>
                                                    )) : <tr></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> 
                                    :''}   

                                    {verMensaje1 ?               
                                    <div className='text-center text-secondary my-4'>
                                        <p>Aqui puede ver todos los abonos que usted a realizado para el pago de prestamos.</p>
                                        <h4>Por favor seleccione un rango de fechas.</h4>
                                    </div> 
                                    :''}               
                                    
                                    {spinerTabla1 ?
                                        <div className="d-flex justify-content-center align-items-center" style={{height:150}}>
                                            <div className="spinner-border" role="status"></div>                 
                                        </div>
                                    :''}
                                </div>
                            </div>
                        </div>
                        <div className={props.data_sesion.rol_id === 0 ? 'col-md-12' : 'col-md-6'}>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className='card-title'>Abonos Registrados</h5>                                    
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label className="mb-1">Desde:</label>
                                            <div className="input-group">
                                                <input 
                                                    type="date" 
                                                    className="form-control" 
                                                    id="fecha_ini_2" 
                                                    name="fecha_ini_2" 
                                                    value={filtro.fecha_ini_2}
                                                    onChange={handleInputFiltroChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label className="mb-1">Hasta:</label>
                                            <div className="input-group">
                                                <input 
                                                    type="date" 
                                                    className="form-control" 
                                                    id="fecha_fin_2" 
                                                    name="fecha_fin_2" 
                                                    value={filtro.fecha_fin_2}
                                                    onChange={handleInputFiltroChange} 
                                                />
                                            </div>
                                        </div>
                                
                                        <div className="col-md-2">
                                            <label className="mb-1">&emsp;</label>
                                            <div className="input-group">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-primary btn-md"
                                                    onClick={handleBuscarAbonosRealizados}
                                                >
                                                    Buscar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {verTable2 ?
                                        <div className='table-container'>
                                            <div className="table-responsive-sm">
                                                <table className='table table-hover'>
                                                    <thead>
                                                    <tr className='text-center'>
                                                        <td><b>Fecha</b></td>
                                                        <td><b>Hora</b></td>
                                                        <td><b>Usuario</b></td>
                                                        <td><b>Valor</b></td>
                                                        <td><b>Observación</b></td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>                                       
                                                    {Array.isArray(listaAbonosRegistrados) ? listaAbonosRegistrados.map((abono, index) => (
                                                        <tr className='text-center' key={index}>
                                                            <td><span className="badge text-bg-dark">{abono.fecha}</span></td>
                                                            <td><span className="badge text-bg-dark">{abono.hora}</span></td>
                                                            <td>{abono.id_comercio} - {abono.nickname}</td>
                                                            <td><span className="badge text-bg-success">$ {formatearMoneda(abono.valor)}</span></td>
                                                            <td style={{fontSize : '12px'}}>{abono.descripcion}</td>
                                                        </tr>
                                                    )) : <tr></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> 
                                    :''}   

                                    {verMensaje2 ?               
                                    <div className='text-center text-secondary my-4'>
                                        <p>Aqui puede consultar todos los abonos que usted ha registrado desde el modulo de usuarios.</p>
                                        <h4>Por favor seleccione un rango de fechas.</h4>
                                    </div> 
                                    :''}               
                                    
                                    {spinerTabla2 ?
                                        <div className="d-flex justify-content-center align-items-center" style={{height:150}}>
                                            <div className="spinner-border" role="status"></div>                 
                                        </div>
                                    :''}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

const mapSateToProps = state => {
    return {
        data_sesion: state.data_sesion,
    }
}

export default connect(mapSateToProps, null)(Abonos)
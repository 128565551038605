import React from 'react';
import { connect } from 'react-redux';

function Modalidades(props){

    return(
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Modalidades de Juego</h5>
                    <div className="activity">
                    {Array.isArray(props.listaModalidades) ? props.listaModalidades.map((modalidad, index) => (
                        <div key={index} className="activity-item d-flex">
                            <div className="activite-label">( {modalidad.prefijo} )</div>
                                <i className='bi bi-circle-fill activity-badge text-success align-self-start'></i>
                            <div className="activity-content">
                                <b>{modalidad.nombre}</b> {modalidad.descripcion}
                            </div>
                        </div>
                    )) : ''}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {
    return {
        listaModalidades: state.listaModalidades
    }
}

export default connect(mapSateToProps, null)(Modalidades)
import React, {useState, useEffect} from 'react';
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import HeaderModulo from "../../../header/HeaderModulo";
import { alerta, toast, formatDate } from '../../../../services/utilities'
import { getPremiosBono } from '../../../../services/Resultados'

function Premios(props){

    const [premios, setPremios] = useState([]);
    const [numeroPremios, setNumerosPremios] = useState(0);
    const [sumaValorPremio, setSumaValorPremio] = useState(0);
    const [verTable, setVerTable] = useState(false);
    const [spinerTabla, setSpinerTabla] = useState(false);
    const [verMensaje, setVerMensaje] = useState(true);
    const [filterTabla, setFilterTabla] = useState('');
    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date()),
        "agrupado": ""
    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
    };

    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }    

    const handleBuscarPremios = async () => {

        setVerMensaje(false);
        setSpinerTabla(true);
        setVerTable(false);

        try {
            await getPremiosBono(filtro.fecha_ini, filtro.fecha_fin).then( response => {

                if(response.status !== undefined && response.status === 200){  
                    if(response.data.premios.length > 0){
                        setNumerosPremios(response.data.premios.length);
                        setPremios(response.data.premios);
                        setVerTable(true);

                        let sum = 0;
                        response.data.premios.forEach(premio => {
                            sum += premio.valor_premio;
                        });
                        setSumaValorPremio(sum);
                    }else{
                        setVerMensaje(true)
                        setVerTable(false);
                    }
                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de premios  (operaciones/premios/handleBuscarPremios) ->'+ error);
        }finally {
            setSpinerTabla(false);
        }
    }

    const filterDataTabla = premios.filter((item) => {

        const comercioMatch = item.comercio !== null && item.comercio  ? item.comercio.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const empresaMatch = item.promotor !== null && item.empresa  ? item.empresa.toLowerCase().includes(filterTabla.toLowerCase()) : '';

        return comercioMatch || empresaMatch ;

    });

    return(
        <React.Fragment>

            <div className="row my-4">
                <div className='col-md-3 mb-3'>
                    <label className="mb-1">Desde:</label>
                    <div className="input-group">
                        <input 
                            type="date" 
                            className="form-control" 
                            id="fecha_ini" 
                            name="fecha_ini" 
                            value={filtro.fecha_ini}
                            onChange={handleInputFiltroChange} 
                        />
                    </div>
                </div>
                <div className='col-md-3 mb-3'>
                    <label className="mb-1">Hasta:</label>
                    <div className="input-group">
                        <input 
                            type="date" 
                            className="form-control" 
                            id="fecha_fin" 
                            name="fecha_fin" 
                            value={filtro.fecha_fin}
                            onChange={handleInputFiltroChange} 
                        />
                    </div>
                </div>
                { verTable ?
                <div className="col-md-3">
                    <label className="mb-1">&emsp;</label>
                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                        <input 
                            type="text" 
                            className="form-control form-control-sm" 
                            placeholder="Buscar..." 
                            aria-label="Buscar" 
                            aria-describedby="basic-addon1" 
                            value={filterTabla}
                            onChange={(e) => setFilterTabla(e.target.value)} 
                        />
                    </div>
                </div>
                : ''}
                <div className="col-md-1">
                    <label className="mb-1">&emsp;</label>
                    <div className="input-group">
                        <button 
                            type="button" 
                            className="btn btn-outline-primary btn-md"
                            onClick={handleBuscarPremios}
                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </div>
            {verTable ?
            <div className='row'>
                <div className="col-md-3">
                    <div className="card info-card sales-card">
                        <div className="card-body">
                            <h5 className="card-title m-0">Cantidad Premios</h5>
                            <h6 className='text-success m-0'><b>{numeroPremios}</b></h6>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card info-card sales-card">
                        <div className="card-body">
                            <h5 className="card-title m-0">Total Premios</h5>
                            <h6 className='text-danger m-0'><b>$ {formatearMoneda(sumaValorPremio)}</b></h6>
                        </div>
                    </div>
                </div>
            </div>
            :''}
            {verTable ?
                <div className="card" style={{ overflow : 'auto', height:"500px"}}>
                    <div className="card-body">
                        <div className='table-container'>
                            <div className="table-responsive-sm">
                                <table className='table table-hover'>
                                    <thead>
                                        <tr className='text-center'  style={{fontSize : '12px'}}>
                                            <td><b>Fecha</b></td>
                                            <td><b># Venta</b></td>
                                            <td><b># Juego</b></td>
                                            <td><b>Empresa</b></td>
                                            <td><b>Comercio</b></td>
                                            <td><b>Modalida</b></td>
                                            <td><b># No Oficial</b></td>
                                            <td><b>Loteria No Oficial</b></td>
                                            <td><b># Oficial</b></td>
                                            <td><b>Loteria Oficial</b></td>
                                            <td><b>Tipo Premio</b></td>
                                            <td><b>Valor Premio</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {Array.isArray(premios) ? filterDataTabla.map((premio, index) => (
                                        <tr key={index} style={{fontSize : '12px'}} className='text-center'>
                                            <td><span className="badge text-bg-dark">{premio.fecha}</span></td>
                                            <td><span className="badge text-bg-warning"># {premio.id_transaccion}</span></td>
                                            <td><span className="badge text-bg-primary"># {premio.juego_id}</span></td>
                                            <td><b>{premio.empresa}</b></td>
                                            <td><b>{premio.id_comercio} - {premio.comercio}</b></td>
                                            <td><b>{premio.modalidad}</b></td>
                                            <td className='table-primary'><b>{premio.numero_no_oficial}</b></td>
                                            <td className='table-primary'><b>{premio.loteria_no_oficial}</b></td>
                                            <td className='table-warning'><b>{premio.numero_oficial}</b></td>
                                            <td className='table-warning'><b>{premio.loteria_oficial}</b></td>
                                            <td><b>{premio.tipo_premio}</b></td>
                                            <td><span className="badge text-bg-danger">${formatearMoneda(premio.valor_premio)}</span></td>
                                        </tr>
                                    )) : ''}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            : ''}
            {spinerTabla ?
            <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                <div className="spinner-border" role="status"></div>                 
            </div>
            :''}
            {verMensaje ?
            <div className='text-center text-secondary my-4'>
                <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                <p>Por favor elija una combinación de filtros diferente.</p>
            </div>
            :''}

        </React.Fragment>
    );
}

export default Premios;
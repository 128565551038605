import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import  profile from "../../../../assets/img/profile.jpg"
import { getUsuario, cambiarPasswordUsuario } from '../../../../services/root'
import { alerta, toast, cerrarModal } from '../../../../services/utilities';
import Swal  from 'sweetalert2';

function EditarUsuario (props){

    useEffect(() => {
        if(props.id_comercio > 0)
            getUsuarioApi();
    },[props.id_comercio]);

    const [spiner, setSpiner] = useState(false);
    const [usuario, setUsuario] = useState({
        "id_usuario": props.id_comercio,
        "id_comercio": "",
        "nombre":"",
        "tipo":"",
        "nueva_contrasena": "",
        "estado_id": "1"
    });

    const handleInputPasswordChange = (event) => {
        const { name, value } = event.target;
        setUsuario({ ...usuario, [name]: value });
    };

    const getUsuarioApi = async () => {

        setSpiner(true);

        try {
            await getUsuario(props.id_comercio).then(response => {
                if(response.status === 200){

                    const usuario = response.data.usuarios[0];
                    setUsuario({ ...usuario, ['estado_id']: usuario.estado_id });
                    setUsuario({ ...usuario, ['nombre']: usuario.nombre });
                    setUsuario({ ...usuario, ['tipo']: usuario.tipo });
                    setUsuario({ ...usuario, ['id_comercio']: usuario.id_comercio });

                }else{
                    alerta('No fue posible cargar la informacin del usuario','','warning');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación del usuario  (root/lista/usuarios) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    const handleGuardarPassword = () => {
        
    
        if(usuario.nueva_contrasena !== undefined && usuario.nueva_contrasena !=''){
            if(usuario.nueva_contrasena.length < 4){
                alerta('La nueva constraseña debe tener minimo 4 caracteres');
                return;
            }
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la información del usuario ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                cambiarPasswordUsuario(usuario).then(response => {
                    if(response.status == 200){
                        cerrarModal('modalEditarUsuario');
                        toast('¡ Perfecto !','Cambio de contraseña exitoso','success');
                    }else{
                        alerta('Se presento un error al intentar guardar la información',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalEditarUsuario" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalEditarUsuario" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Usuarios</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <section className="section profile"> 
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card">
                                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                                <img src={ profile } alt="Profile" className="rounded-circle" />
                                                <h2>{usuario.nombre}</h2>
                                                <h3>{usuario.tipo}</h3>
                                                <div className="text-center">
                                                    {usuario.estado_id === 1
                                                        ?<span className="badge rounded-pill text-bg-success fs-6 m-2">Activo</span>
                                                        :<span className="badge rounded-pill text-bg-danger fs-6 m-2">Inactivo</span>
                                                    }
                                                </div>
                                                <h5 className="card-title">Codigo <span> | {usuario.id_comercio}</span></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card">
                                            <div className="card-body p-3">                                               
                                                <div className="row mb-3">
                                                    <label htmlFor="nueva_contrasena" className="col-md-4 col-lg-3 col-form-label">Estado</label>
                                                    <div className="col-md-8 col-lg-9">
                                                    <select 
                                                            className="form-select" 
                                                            id="estado_id" 
                                                            name="estado_id" 
                                                            aria-label="State"
                                                            value={usuario.estado_id}
                                                            onChange={handleInputPasswordChange}
                                                        >
                                                            <option value="1">Activo</option>
                                                            <option value="3">Inactivo</option>
                                                        </select>
                                                    </div>
                                                </div>                                                
                                                <div className="row mb-3">
                                                    <label htmlFor="nueva_contrasena" className="col-md-4 col-lg-3 col-form-label">Nueva Contraseña</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input name="nueva_contrasena" type="password" className="form-control" id="nueva_contrasena" value={usuario.nueva_contrasena} onChange={handleInputPasswordChange}/>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <button type="button" className="btn btn-primary" onClick={handleGuardarPassword}>Guardar Cambios</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
        id_comercio: state.id_comercio
    }
}

export default  connect(mapSateToProps,null)(EditarUsuario);
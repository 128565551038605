export const reducer = (state, action) => {
    switch(action.type){
        case "SET_DATA_SESION":
            return {
                ...state,
                data_sesion: action.payload
            }
        case "SET_TABLA_LOTERIAS":
            return {
                ...state,
                tableLoterias: action.payload
            }
        case "SET_TABLA_PLANES_PAGO":
            return {
                ...state,
                tablaPlanesPago: action.payload
            }
        case "SET_ID_LOTERIA":
            return {
                ...state,
                id_loteria: action.payload
            }
        case "SET_LOTERIA":
            return {
                ...state,
                loteria: action.payload
            }
        case "SET_LOTERIAS_HOY":
            return {
                ...state,
                loterias_hoy: action.payload
            }
        case "SET_LISTA_ZONAS":
            return {
                ...state,
                listaZonas: action.payload
            }
        case "SET_LISTA_MODALIDADES":
            return {
                ...state,
                listaModalidades: action.payload
            }
        case "SET_ID_ZONA":
            return {
                ...state,
                id_zona: action.payload
            }
        case "SET_DATA_USUARIO":
            return {
                ...state,
                data_usuario: action.payload
            }
        case "SET_PLAN_PAGO":
            return {
                ...state,
                plan_pago: action.payload
            }
        case "SET_ID_PLAN_PAGO":
            return {
                ...state,
                id_plan_pago: action.payload
            }
        case "SET_LISTA_RIFAS":
            return {
                ...state,
                listaRifas: action.payload
            }
        case "SET_ID_RIFA":
            return {
                ...state,
                id_rifa: action.payload
            }
        case "SET_LISTA_ENCABEZADO_RESULTADO":
            return {
                ...state,
                listaEncabezadoResultado: action.payload
            }
        case "SET_ID_ENCABEZADO_RESULTADO":
            return {
                ...state,
                id_encabezado_resultado: action.payload
            }
        case "SET_TABLA_BLOQUEOS":
            return {
                ...state,
                    bloqueos: action.payload
            }
        case "SET_ID_BLOQUEO":
            return {
                ...state,
                    id_bloqueo: action.payload
            }
        case "SET_TABLA_COMERCIOS":
            return {
                ...state,
                tablaComercios: action.payload
            }
        case "SET_ID_COMERCIO":
            return {
                ...state,
                id_comercio: action.payload
            }
        case "SET_GESTIONANDO":
            return {
                ...state,
                gestionando: action.payload
            }
        case "SET_ID_COMERCIO_GESTIONADO":
            return {
                ...state,
                id_comercio_gestionado: action.payload
            }
        case "SET_ID_COMERCIO_SELECCIONADO":
            return {
                ...state,
                id_comercio_seleccionado: action.payload
            }
        case "SET_RESUMEN_CARTERA":
            return {
                ...state,
                resumen_cartera: action.payload
            }
        case "SET_RESUMEN_CARTERA_INDIVIDUAL":
            return {
                ...state,
                resumen_cartera_individual: action.payload
            }
        case "SET_GESTION_LOTERIAS":
            return {
                ...state,
                gestion_loterias: action.payload
            }
        case "SET_ID_TRANSACCION":
            return {
                ...state,
                id_transaccion: action.payload
            }
        default:
            return state
    }
};
import InstaceAxios  from "./Api";

export const getCarteaVendedores = async (fecha, tipoComercio) => {
    const result = await InstaceAxios.get(`cartera/comercios?fecha=${fecha}&tipo_comercio=${tipoComercio}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getMovimientos = async (fecha_ini, fecha_fin, id_comercio) => {
    const result = await InstaceAxios.get(`cartera/movimientos?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}&id_comercio=${id_comercio}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getReporteCartera = async (fecha_ini, fecha_fin) => {
    const result = await InstaceAxios.get(`reporte/cartera?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
        .then(res => res)
        .catch(error => error)
    return result
}
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setListaRifas } from '../../../../actions'
import { setTablaLoteria } from '../../../../actions'
import { alerta } from '../../../../services/utilities';
import { getLoterias } from '../../../../services/Loterias';
import { getRifa } from '../../../../services/Rifa';
import Header from "../../../header/Header";
import Aside from "../../../aside/Aside";
import HeaderModulo from "../../../header/HeaderModulo";
import Nueva from "./Nueva";
import Lista from "./Lista";
import Editar from './Editar';

function Rifas(props){

    useEffect(() => {

        getRifa().then(response => {
            if(response.status === 200){
                props.setListaRifas(response.data.data)
            }else{
                alerta('No se cargo la tabla de loterias o no hay loterias actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });

        getLoterias().then(response => {
            if(response.status === 200){
                props.setTablaLoteria(response.data)
            }else{
                alerta('No se cargo la tabla de loterias o no hay loterias actualemente creadas',response.response.data.status_message??response.message,'warning');
            }
        });
    
    }, []);

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className="row">
                        {props.data_sesion.rol_id == 0 ?
                            <Nueva></Nueva>
                        :''}
                        <Lista></Lista>
                    </div>
                </section>
                <Editar></Editar>
            </main>
        </React.Fragment>
    );
}


const mapDispatchToProps  = {
    setListaRifas,
    setTablaLoteria,
};

const mapSateToProps = state => {
    return {
        data_sesion: state.data_sesion,
    }
}


export default connect(mapSateToProps, mapDispatchToProps)(Rifas)
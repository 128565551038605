import React from "react";
import Item from "./item_menu";

function Cartera(props){

    return(
        <React.Fragment>
            <li className="nav-item">
                <a className="nav-link collapsed" data-bs-target="#cartera-nav" data-bs-toggle="collapse" href="#">
                    <i className="bi bi-currency-dollar"></i>
                    <span>Cartera</span>
                    <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="cartera-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                    {props.rol_id !== 0 ? <Item nombre="Mi Cartera" ruta="/cartera/cartera"></Item> : ''}
                    {props.rol_id <= 2 ? <Item nombre={props.rol_id === 0 ? 'Cartera' : props.rol_id === 1 ? 'Cartera Promotores' : 'Cartera Vendedores'} ruta="/cartera/liquidacion"></Item> : ''}
                </ul>
            </li>
        </React.Fragment>
    );
}

export default Cartera;
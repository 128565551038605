import InstaceAxios  from "./Api";

export const getConfiguracion = async (cifras) => {
    const result = await InstaceAxios.get(`bono/configuracion?cifras=${cifras}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const guardarCuatroCifras = async (data) => {
    const result = await InstaceAxios.post('bono/configuracion/actualizar', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const getDataTablaLiquidacion = async (id_empresa, fecha_ini, fecha_fin) => {
    const result = await InstaceAxios.get(`bono/dataTablaLiquidacion?id_empresa=${id_empresa}&fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
        .then(res => res)
        .catch(error => error)
    return result
}
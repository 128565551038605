import React from "react";

function Footer(){

    return(
        <React.Fragment>
            <footer id="footer" className="footer">
                <div className="copyright">
                   
                </div>
                <div className="credits">
                    
                </div>
            </footer>
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
        </React.Fragment>
    );

}

export default Footer
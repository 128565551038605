import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { alerta, toast, cerrarModal, formatDate, formatearMonedaValue } from '../../../services/utilities';
import { getMovimientos } from '../../../services/Cartera';
import Swal  from 'sweetalert2';

function Movimientos (props){

    useEffect(() => {
        if(props.id_comercio_seleccionado !== 0){
            handleConsultarMovimientos(formatDate(new Date()), formatDate(new Date()), props.id_comercio_seleccionado)
        }
        setFiltros({
            "fecha_ini": formatDate(new Date()),
            "fecha_fin": formatDate(new Date()),
        })
    },[props.id_comercio_seleccionado]);

    const [spiner, setSpiner] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [verMnesaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(false);
    const [movimeintos, setMovimientos] = useState([]);
    const [filter, setFilter] = useState('');

    const [filtros, setFiltros] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date()),
    });

    const handleInputFiltroFechasChange = (event) => {
        const { name, value } = event.target;
        setFiltros({ ...filtros, [name]: value });
    };

    const handleConsultarMovimientos = async (fecha_ini, fecha_fin, id_comercio) => {

        setVerMensaje(false);
        setVerTable(false);
        setIsDisabled(true);
        setSpiner(true);

        try {

            await getMovimientos(fecha_ini, fecha_fin, id_comercio).then( response => {

                if(response.status !== undefined && response.status === 200){
                    if(response.data.movimientos.length === 0){
                        setVerMensaje(true);
                        setVerTable(false);
                        setMovimientos([]);
                    }else{
                        setIsDisabled(false);
                        setMovimientos(response.data.movimientos);
                        setVerTable(true);
                        toast('¡ Perfecto !','Movimientos Cargados','success');
                    }
                }else{
                    alerta('Error al consular los movimientos en la base de datos');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de movimientos (cartera/Movimientos/handleConsultarMovimientos)');
        }finally {
            setSpiner(false);
        }
    }

    const filteredData = movimeintos.filter((item) => {

        const tipoTransaccionMatch = item.tipo_transaccion !== null ? item.tipo_transaccion.toLowerCase().includes(filter.toLowerCase()) : '';
        const FechaMatch = item.fecha !== null ? item.fecha.toLowerCase().includes(filter.toLowerCase()) : '';
        const valorMatch =  item.valor !== null ?  item.valor.toString().includes(filter) : '';
        const descripcionMatch = item.descripcion !== null ?  item.descripcion.toString().includes(filter) : '';
        return tipoTransaccionMatch || FechaMatch || valorMatch || descripcionMatch;

    });

    return (
        <React.Fragment>

            <div className="modal fade" id="modalMovimientos" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalMovimientos" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Movimientos</h1>
                            <span className="badge text-bg-primary mx-2">Codigo: {props.id_comercio_seleccionado}</span>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row my-3 mb-0">
                                        <div className="col-md-3">
                                            <label className="mb-1">Desde:</label>
                                            <div className="input-group">
                                                <input 
                                                    type="date" 
                                                    className="form-control" 
                                                    id="fecha_ini" 
                                                    name="fecha_ini" 
                                                    value={filtros.fecha_ini}
                                                    onChange={handleInputFiltroFechasChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="mb-1">Hasta:</label>
                                            <div className="input-group">
                                                <input 
                                                    type="date" 
                                                    className="form-control" 
                                                    id="fecha_fin" 
                                                    name="fecha_fin" 
                                                    value={filtros.fecha_fin}
                                                    onChange={handleInputFiltroFechasChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="mb-1">&emsp;</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                                <input 
                                                    type="text" 
                                                    className="form-control form-control-sm" 
                                                    placeholder="Buscar..." 
                                                    aria-label="Buscar" 
                                                    aria-describedby="basic-addon1" 
                                                    disabled={isDisabled}
                                                    value={filter}
                                                    onChange={(e) => setFilter(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="mb-1">&emsp;</label>
                                            <div className="input-group">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-outline-primary btn-md"                                                    
                                                    onClick={() => handleConsultarMovimientos(filtros.fecha_ini, filtros.fecha_fin, props.id_comercio_seleccionado)}
                                                >
                                                    <i 
                                                        className="bi bi-arrow-repeat" 
                                                        onClick={() => handleConsultarMovimientos(filtros.fecha_ini, filtros.fecha_fin, props.id_comercio_seleccionado)}
                                                    >
                                                    </i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {verTable?
                            <div className='table-container m-1'>
                                <div className="table-responsive-sm">
                                    <table className='table table-hover'  style={{fontSize : '12px'}}>
                                        <thead>
                                            <tr>
                                                <td><b>Tipo</b></td>
                                                <td><b>Fecha</b></td>
                                                <td><b>$ Valor</b></td>
                                                <td><b>Observación</b></td>
                                                <td><b>Usuario</b></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Array.isArray(movimeintos) ? filteredData.map((item, index) => (
                                            <tr key={index}>
                                                <td className={item.tipo_transaccion==='EGRESO' ? 'text-danger' : 'text-success' }>
                                                    {item.tipo_transaccion ==='EGRESO' ? 
                                                        <i className="bi bi-arrow-down-circle mx-1"></i> 
                                                        :
                                                        <i className="bi bi-arrow-up-circle mx-1"></i>
                                                    }
                                                    {item.tipo_transaccion}
                                                </td>
                                                <td>{item.fecha}</td>
                                                <td><b>{formatearMonedaValue(item.valor)}</b></td>
                                                <td className="text-secondary">{item.descripcion}</td>
                                                <td className="text-center"><span className="badge text-bg-info">{item.nickname}</span></td>
                                            </tr>
                                        )) : <tr></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : ''}
                            {spiner ?
                            <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                                <div className="spinner-border" role="status"></div>                 
                            </div>
                            : ''}
                            {verMnesaje?
                            <div className='text-center text-secondary my-4'>
                                <h3>No se han encontrado registros seleccionados</h3>
                                <p>Por favor, elija un rango de fechas para buscar información sobre los movimientos registrados</p>
                            </div>
                            :''}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion,
        id_comercio_seleccionado: state.id_comercio_seleccionado
    }
}
    
export default  connect(mapSateToProps,null)(Movimientos);
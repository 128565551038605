import React from "react";
import { connect } from "react-redux";
import Header from "../../header/Header";
import Aside from "../../aside/Aside";
import HeaderModulo from "../../header/HeaderModulo";
import  profile from "../../../assets/img/profile.jpg"
import Password from "./Password";
import Informacion_usuario from "./informacion_usuario";
import Configuracion from "./Configuracion";

function Perfil(props){

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section profile"> 
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                    <img src={ profile } alt="Profile" className="rounded-circle" />
                                    <h2>{props.data_sesion.nombre_usuario}</h2>
                                    <h3>{props.data_sesion.tipo_comercio}</h3>
                                    <h5 className="card-title">Codigo <span> | {props.data_sesion.id_comercio}</span></h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-body p-3">
                                    <ul className="nav nav-tabs nav-tabs-bordered">
                                        <li className="nav-item">
                                            <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#info-usuario">Información de usuario</button>
                                        </li>

                                        <li className="nav-item">
                                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#confi-usuario">Configuración</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#cambiar-password">Cambiar Contraeña</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content p-3">
                                    <div className="tab-pane fade show active info-usuario" id="info-usuario">
                                        <Informacion_usuario></Informacion_usuario>
                                    </div>
                                    <div className="tab-pane fade confi-usuario" id="confi-usuario">
                                        <Configuracion></Configuracion>
                                    </div>
                                    <div className="tab-pane fade cambiar-password p-3" id="cambiar-password">
                                        <Password></Password>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        data_sesion: state.data_sesion
    }
}

export default connect(mapSateToProps, null)(Perfil);
import React from "react";
import logospace from '../../assets/img/logospace.png'

const Unauthorized = () => (
    <main>
    <div className="container">
      <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
        <h1>401</h1>
        <h2>Tu sesión no es válida o ha expirado. Por favor, inicia sesión nuevamente.</h2>
        <a className="btn" href="/">INICIAR SESION</a>
        <img src={logospace} className="img-fluid py-5" alt="Page Not Found" width={180} />
        <div className="credits">
        </div>
      </section>
    </div>
  </main>
);

export default Unauthorized

import React, { useState } from 'react';
import { alerta, toast } from '../../../services/utilities';
import { cambiarPassword } from '../../../services/Usuario';
import Swal from 'sweetalert2';

function Password(){

    const [password, setPassword] = useState({
        "contrasena_actual": "",
        "nueva_contrasena": "",
        "confirmar_contrasena": ""
    });

    const handleInputPasswordChange = (event) => {
        const { name, value } = event.target;
        setPassword({ ...password, [name]: value });
    };

    const handleGuardarPassword = () => {

        if(password.contrasena_actual.length < 4){
            alerta('La contraseña actual no es valida.');
            return;
        }else if(password.nueva_contrasena.length < 4){
            alerta('La nueva constraseña no es valida.');
            return;
        }else if(password.nueva_contrasena != password.confirmar_contrasena){
            alerta('Las contraseñas no coinciden.');
            return;
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la información del usuario ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                cambiarPassword(password).then(response => {
                    if(response.status == 200){
                        setPassword({
                            "contrasena_actual": "",
                            "nueva_contrasena": "",
                            "confirmar_contrasena": ""
                        })
                        toast('¡ Perfecto !','Cambio de contraseña exitoso','success');
                    }else{
                        alerta('Se presento un error al intentar guardar la información',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    return(
        <React.Fragment>
            <div className="row mb-3">
                <label htmlFor="contrasena_actual" className="col-md-4 col-lg-3 col-form-label">Contraseña Actual</label>
                <div className="col-md-8 col-lg-9">
                    <input name="contrasena_actual" type="password" className="form-control" id="contrasena_actual" value={password.contrasena_actual} onChange={handleInputPasswordChange}/>
                </div>
            </div>

            <div className="row mb-3">
                <label htmlFor="nueva_contrasena" className="col-md-4 col-lg-3 col-form-label">Nueva Contraseña</label>
                <div className="col-md-8 col-lg-9">
                    <input name="nueva_contrasena" type="password" className="form-control" id="nueva_contrasena" value={password.nueva_contrasena} onChange={handleInputPasswordChange}/>
                </div>
            </div>
            <div className="row mb-3">
                <label htmlFor="confirmar_contrasena" className="col-md-4 col-lg-3 col-form-label">Confirmar Contraseña</label>
                <div className="col-md-8 col-lg-9">
                    <input name="confirmar_contrasena" type="password" className="form-control" id="confirmar_contrasena" value={password.confirmar_contrasena} onChange={handleInputPasswordChange}/>
                </div>
            </div>
            <div className="text-center">
                <button type="button" className="btn btn-primary" onClick={handleGuardarPassword}>Cambiar Contraseña</button>
            </div>
        </React.Fragment>
    );
}

export default Password;
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { reducer } from './reducers/index'

const root = ReactDOM.createRoot(document.getElementById('root'));

const initialState = {
  "data_sesion": {},
  "data_usuario": {},
  "tableLoterias": [],
  "tablaPlanesPago": [],
  "tablaComercios": [],
  "id_loteria": 0,
  "loteria": {},
  "loterias_hoy": {},
  "listaZonas": [],
  "listaModalidades":[],
  "id_zona": 0,
  "plan_pago":{},
  "id_plan_pago": 0,
  "listaRifas": [],
  "id_rifa": 0,
  "listaEncabezadoResultado": [],
  "id_encabezado_resultado": 0,
  "bloqueos": [],
  "id_bloqueo": 0,
  "id_comercio": 0,
  "gestionando": 'NO',
  "id_comercio_gestionado":0,
  "id_comercio_seleccionado":0,
  "id_transaccion":0,
  "resumen_cartera":{},
  "resumen_cartera_individual":{},
  "gestion_loterias": false
}

const store = createStore(reducer, initialState)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);


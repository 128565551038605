import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setTablaBloqueos } from '../../../../actions'
import { getBloqueo, getBloqueos, editarBloqueo, eliminarBloqueo } from "../../../../services/Bloqueo";
import { alerta, toast, cerrarModal } from '../../../../services/utilities';
import Swal  from 'sweetalert2';

function Editar(props){

    useEffect(() => {  

        if(props.id_bloqueo != 0){
            getBloqueo(props.id_bloqueo).then(response => {
                if(response.status === 200){
                    
                    const data = response.data.data[0];
                    setBloqueo({
                        "id_bloqueo": data.id_bloqueo,
                        "loteria_id": data.loteria_id,
                        "tipo_bloqueo": data.tipo_bloqueo,
                        "valor_tipo_bloqueo": data.valor_tipo_bloqueo,
                        "valor_venta": data.valor_venta,
                        "valor_cubierta": data.valor_cubierta,
                        "estado_id": data.estado_id,
                        "categoria_modalidad_juego_id": data.categoria_modalidad_juego_id,
                        "combinado": data.combinado
                    })
                    setMostrarSelectCombinado(data.tipo_bloqueo === "cifras" && data.categoria_modalidad_juego_id == '1' ? true : false);
                }else{
                    alerta('Error al cargar la infromación del bloqueo');
                }
            })
        }

    }, [props.id_bloqueo]);

    const [mostrarSelectCombinado, setMostrarSelectCombinado] = useState(false);
    const handleRefrescarTablaBloqueos = () => {

        getBloqueos().then(response => {
             if(response.status === 200){
                cerrarModal('modalEditarBloqueo');
                props.setTablaBloqueos(response.data)
             }else{
                 alerta('No se cargo la tabla de bloqueos o no hay bloqueos actualemente creadas',response.response.data.status_message??response.message,'warning');
             }
         });
     }

    const [bloqueo, setBloqueo] = useState({
        "id_bloqueo": "",
        "loteria_id":"",
        "tipo_bloqueo":"",
        "valor_tipo_bloqueo":"",
        "valor_venta":"",
        "valor_cubierta":0,
        "categoria_modalidad_juego_id":"",
        "combinado":"0"
    })

    const handleInputBloqueoChange = (event) => {
        const { name, value } = event.target;
        setBloqueo({ ...bloqueo, [name]: value });

        if(name === "categoria_modalidad_juego_id"){
            setMostrarSelectCombinado(value === "1" && bloqueo.tipo_bloqueo == 'cifras' ? true : false);
        }else if(name === "tipo_bloqueo"){
            setMostrarSelectCombinado(value === "cifras" && bloqueo.categoria_modalidad_juego_id == '1' ? true : false);
        }
    };

    const handleValidarCampos = () =>{

        let msg = false;

        if(bloqueo.loteria_id == ''){
            msg = 'Debe seleccionar una loteria';
        }else if(bloqueo.categoria_modalidad_juego_id == ''){
            msg = 'Debe seleccionar una modalidad de juego.';
        }else if(bloqueo.tipo_bloqueo == ''){
            msg = 'Debe seleccionar un tipo de bloqueo.';
        }else if(bloqueo.tipo_bloqueo == 'cifras' && (bloqueo.valor_tipo_bloqueo > 4 || bloqueo.valor_tipo_bloqueo < 1)){
            msg = 'La cantidad de cifras tiene que ser entre 1 y 4.';
        }else if(bloqueo.tipo_bloqueo == 'numero' && (bloqueo.valor_tipo_bloqueo < 0 ||  bloqueo.valor_tipo_bloqueo == '')){
            msg = 'El numero debe ser cero o mayor.';
        }else if(bloqueo.valor_venta < 1){
            msg = 'El campo valor debe ser mayor a cero.';
        }else if(props.data_sesion.venta_cubierta === 1 && bloqueo.valor_cubierta > bloqueo.valor_venta){
            msg = 'El valor de cubierta no puede ser mayor al valor del tope';
        }
            
        return msg;
    }

    const handleEditarBloqueo = () =>{

        if(handleValidarCampos()){
            alerta(handleValidarCampos());
            return;
        }

        Swal.fire({
            title: "¿ Esta seguro de guardar la información ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Guardar'
        }).then((result) => {
            if (result.isConfirmed) {
                editarBloqueo(bloqueo).then(response => {
                    if(response.status == 200){
                        handleRefrescarTablaBloqueos()
                        toast('¡ Perfecto !','Bloqueo editado exitosamente','success');
                    }else{
                        alerta('Se presento un error al intentar guardar la informacion',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    const handleEliminarBloqueo = () => {

        Swal.fire({
            title: "¿ Esta seguro de eliminar el bloqueo ?",
            text: "Si esta seguro presione el boton Guardar de lo contrario Cancelar",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar'
        }).then((result) => {
            if (result.isConfirmed) {
                eliminarBloqueo(bloqueo.id_bloqueo).then(response => {
                    if(response.status == 200){
                        handleRefrescarTablaBloqueos()
                        toast('¡ Perfecto !','Bloqueo eliminado exitosamente','success');
                    }else{
                        alerta('Se presento un error al intentar eliminar el bloqueo',response.response.data.status_message??response.message);
                    }
                });
            }
        });
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="modalEditarBloqueo" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalEditarBloqueo" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Editar Bloqueo</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <select 
                                            className="form-select" 
                                            id="loteria_id" 
                                            name="loteria_id" 
                                            aria-label="State"
                                            value={bloqueo.loteria_id}
                                            disabled={true}
                                        >
                                            <option value="">-- Seleccion --</option>
                                            {props.tableLoterias.data != undefined ? props.tableLoterias.data.map(opcion => (
                                                <option key={opcion.id_loteria} value={opcion.id_loteria}>{opcion.prefijo}</option>
                                            )):''}
                                        </select>
                                        <label htmlFor="loteria_id">Loteira:</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <select 
                                            className="form-select" 
                                            id="categoria_modalidad_juego_id" 
                                            name="categoria_modalidad_juego_id" 
                                            aria-label="State" 
                                            value={bloqueo.categoria_modalidad_juego_id}
                                            onChange={handleInputBloqueoChange}                                            
                                        >
                                            <option value="">-- Seleccion --</option>
                                            {props.tieneChance ? <option value="1">APUESTA</option> : ''}
                                            {props.tieneQuinta ? <option value="2">QUINTA</option> : ''}
                                            {props.tieneAstro ? <option value="3">ASTROS</option> : ''}
                                        </select>
                                        <label htmlFor="tipo_bloqueo">Modalidad Juego:</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <select 
                                            className="form-select" 
                                            id="tipo_bloqueo" 
                                            name="tipo_bloqueo" 
                                            aria-label="State" 
                                            value={bloqueo.tipo_bloqueo}
                                            onChange={handleInputBloqueoChange}                                            
                                        >
                                            <option value="">-- Seleccion --</option>
                                            <option value="cifras">Cantidad Cifras</option>
                                            <option value="numero">Numero</option>
                                        </select>
                                        <label htmlFor="tipo_bloqueo">Tipo de Bloqueo:</label>
                                    </div>
                                </div>
                                {mostrarSelectCombinado?
                                <div className="col-md-6 pt-3">
                                    <div className="form-floating">
                                        <select 
                                            className="form-select" 
                                            id="combinado" 
                                            name="combinado" 
                                            aria-label="State" 
                                            value={bloqueo.combinado}
                                            onChange={handleInputBloqueoChange}                                            
                                        >
                                            <option value="0">NO</option>
                                            <option value="1">SI</option>
                                        </select>
                                        <label htmlFor="tipo_bloqueo">Combinado</label>
                                    </div>
                                </div>
                                :''}
                                <div className='col-md-6'>
                                <div className="form-floating mb-3">
                                    <select 
                                        className="form-select" 
                                        id="estado_id" 
                                        name="estado_id" 
                                        aria-label="State"
                                        value={bloqueo.estado_id}
                                        onChange={handleInputBloqueoChange}
                                    >
                                        <option value="1">Activo</option>
                                        <option value="3">Inactivo</option>
                                    </select>
                                    <label htmlFor="id_loteria_sugerida">Estado</label>
                                </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="number"
                                            className="form-control"
                                            id="valor_tipo_bloqueo"
                                            name="valor_tipo_bloqueo"
                                            value={bloqueo.valor_tipo_bloqueo}
                                            onChange={handleInputBloqueoChange}                                           
                                        />
                                        <label htmlFor="valor_tipo_bloqueo">Cifras / Numero:</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="number"
                                            className="form-control"
                                            id="valor_venta"
                                            name="valor_venta"
                                            value={bloqueo.valor_venta}
                                            onChange={handleInputBloqueoChange}                                            
                                        />
                                        <label htmlFor="valor_venta">$ valor:</label>
                                    </div>
                                </div>
                                {props.data_sesion.venta_cubierta === 1 ?
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input 
                                            type="number"
                                            className="form-control"
                                            id="valor_cubierta"
                                            name="valor_cubierta"
                                            value={bloqueo.valor_cubierta}
                                            onChange={handleInputBloqueoChange}                                            
                                        />
                                        <label htmlFor="valor_cubierta">$ valor Cubierta:</label>
                                    </div>
                                </div>
                                :''}   
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleEditarBloqueo}>Guardar</button>
                            <button type="button" className="btn btn-danger" onClick={handleEliminarBloqueo}>Eliminar</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {
    return {
        tableLoterias:state.tableLoterias,
        id_bloqueo: state.id_bloqueo,
        data_sesion: state.data_sesion
    }
}

const mapDispatchToProps  = {
    setTablaBloqueos
};

export default  connect(mapSateToProps, mapDispatchToProps)(Editar);
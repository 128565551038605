import React from "react";
import { Link } from "react-router-dom";

function MenuRoot(){
    
    return(
        <React.Fragment>
            <li className="nav-item">
                <Link to="/root/empresas" className="nav-link" href="#">
                    <i className="bi bi-building"></i>
                    <span>Empresas</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/root/usuarios" className="nav-link" href="#">
                    <i className="bi bi-person-gear"></i>
                    <span>Usuarios</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/root/ventas" className="nav-link" href="#">
                    <i className="bi bi-cart-x"></i>
                    <span>Ventas Eliminadas</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/root/bono" className="nav-link" href="#">
                    <i className="bi bi-dice-3"></i>
                    <span>Bono Millonario</span>
                </Link>
            </li>
        </React.Fragment>
    );
}

export default MenuRoot;
import React from "react";

function HeaderModulo(props){

    return(
        <React.Fragment>
            <div className="pagetitle">
                <h1>{props.nombre_modulo}</h1>
            </div>
        </React.Fragment>
    );
}

export default HeaderModulo
import React, {useState, useEffect} from 'react';
import { getTicket } from '../../../services/Reportes';
import { alerta, toast } from '../../../services/utilities';

import { useLocation } from 'react-router-dom';

function Ticket(){

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id_transaccion = searchParams.get('venta');
    const [spiner, setSpiner] = useState(false);
    const [detalleVenta, setDetalleVenta] = useState({
        ticket : {}
    });
    const [verMnesaje, setVerMensaje] = useState(false);
    const [verDetalle, setVerDetalle] = useState(false);
 
    useEffect(() => {
        handleConsultarTransaccion();
    }, [id_transaccion]);

    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }

    const handleConsultarTransaccion= async () => {
        
        setSpiner(true);
        setVerMensaje(true);
        setVerDetalle(false);

        try {
            await getTicket(id_transaccion).then( response => {
                if(response.status !== undefined && response.status === 200){
                    setVerMensaje(false);
                    setVerDetalle(true)
                    console.log(response.data);
                    setDetalleVenta(response.data)
                }else{
                    setVerMensaje(true);
                }
            });
         }catch (error) {
            alerta('Error no controlado al cargar la infromación del select de usuarios (reportes/DetalleVenta/handleConsultarTransaccion) ->'+ error);
        }finally {
            setSpiner(false);
        }
    } 

    return(
        <React.Fragment>           
            <div className='content mt-2' style={{maxWidth: '500px'}}>
                {verDetalle?
                <div className='row'>
                    <div className='col-md-12'>
                        <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Fecha</div>
                                </div>
                                <span className="badge bg-dark rounded-pill mx-1">{detalleVenta.ticket.fecha}</span>
                                <span className="badge bg-dark rounded-pill">{detalleVenta.ticket.hora}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Venta</div>
                                </div>
                                <span className="badge bg-success rounded-pill"># {detalleVenta.ticket.id_transaccion}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Tipo Juego</div>
                                </div>
                                <span>{detalleVenta.ticket.modalidad}</span>
                            </li>
                            {detalleVenta.ticket.modalidad === 'RIFA'?
                            <>
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Que se rifa</div>
                                    </div>
                                    <span>{detalleVenta.ticket.rifa}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">Cuando Juega</div>
                                    </div>
                                    <span>{detalleVenta.ticket.fecha_termina}</span>
                                </li>
                            </>
                            :''}
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Valor</div>
                                </div>
                                <span><b>$ {formatearMoneda(detalleVenta.ticket.valor)}</b></span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Loterias</div>
                                </div>
                                {Array.isArray(detalleVenta.loterias) ? detalleVenta.loterias.map((loteria, index) => (
                                    <span key={index} className="badge text-bg-primary mx-1">{loteria.prefijo}</span>
                                )) : ''}
                            </li>
                        </ul>
                        <hr></hr>
                        <table className='table table-hover text-center'>
                            <thead>
                                <tr>
                                    <td><b>Numero</b></td>
                                    <td><b>Modalidad</b></td>
                                    <td><b>$ valor</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(detalleVenta.numeros) ? detalleVenta.numeros.map((numero, index) => (
                                    <tr key={index}>
                                        <td>{numero.numero} { detalleVenta.ticket.modalidad === 'QUINTA' ? numero.serie : detalleVenta.ticket.modalidad === 'ASTROS' ? numero.astro : ''} </td>
                                        <td>{numero.modalidad_juego}</td>
                                        <td>{detalleVenta.ticket.categoria_modalidad_juego_id === 4 ? '-' : formatearMoneda(numero.valor)}</td>
                                    </tr>
                                )) : <tr><td colSpan="3">No hay numeros disponibles</td></tr>}
                            </tbody>
                        </table>
                    </div>
                    {detalleVenta.ticket.modalidad == 'RIFA' && detalleVenta.ticket.img !== null &&detalleVenta.ticket.img.length > 0 ?
                        <div className='col-md-12 text-center'>
                            <img
                            src={'https://www.apilados.site/public/imagenes/rifa/'+detalleVenta.ticket.img}
                            alt="Preview de la Imagen"
                            style={{ maxWidth: '50%', maxHeight: '300px' }}
                            />
                        </div>
                    :''}
                    {detalleVenta.ticket.categoria_modalidad_juego_id === 4 ?
                        <div className='col-md-12  text-center'>
                            <div className="alert alert-success m-2" role="alert">
                                <p className='m-0' style={{fontSize:"12px"}}><b>Si aciertas 2 números en ambas loterías selecionadas ganaras</b></p>
                                <h5 className='text-success m-0'><b>$ {formatearMoneda(detalleVenta.bono.valor_premio_mayor)}</b></h5>
                                <p className='m-0' style={{fontSize:"12px"}}><b>Si aciertas un número por cualquier lotería ganaras</b></p>
                                <h5 className='text-success m-0'><b>$ {formatearMoneda(detalleVenta.bono.valor_premio_menor)}</b></h5>
                            </div>
                        </div>
                    :''}
                </div>:''}
            {spiner ?
                <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '400px'}}>
                    <div className="spinner-border" role="status"></div>                 
                </div>
            :''}
            {verMnesaje ?
                <div className='text-center text-secondary my-4'>
                    <h3>Sin resultado de búsqueda para la venta.</h3>
                    <p>Es posible que la inforación ya no este diponible.</p>
                </div>
            :''}
            </div>
            <br></br>
        </React.Fragment>
    );
}

export default Ticket